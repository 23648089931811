import { Box, GridItem } from '@chakra-ui/react';
import Center from '@components/Center';
import Flex from '@components/Flex';
import Link from '@components/Link';
import Typography from '@components/Typography';
import { useModal } from '@hooks/ui/useModal';
import { EditPencilSmall } from '@icons/index';
import { CreateEditCategoryModal } from '@pages/MetricCatalog/CreateEditCategoryModal/CreateEditCategoryModal';
import { useMemo, useRef } from 'react';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { CategoryV2, MetricMetadataV2 } from 'src/types/metric';
import { useCategoriesWithBrokenMetrics } from './hooks/useCategoriesWithBrokenMetric';
import { MetricCardV2 } from './MetricCardV2';
import classes from './MetricCatalogCategoryV2.module.scss';
import { getCategoryKey } from '@pages/MetricCatalog/utils';
import { CellMeasurerCache } from 'react-virtualized';

export function MetricCatalogCategoryV2({
	metricCategory,
	getMetricHref,
	handleMetricClick,
	cache,
}: {
	metricCategory: CategoryV2;
	getMetricHref: (metric: MetricMetadataV2) => string;
	handleMetricClick: (metric: MetricMetadataV2) => void;
	cache: CellMeasurerCache;
}) {
	const { name: categoryName, description: categoryDescription, metrics, id: categoryId } = metricCategory;
	const ref = useRef<HTMLDivElement>(null);
	const { reportEvent } = useReportEvent();
	const { categoriesWithBrokenMetrics } = useCategoriesWithBrokenMetrics();
	const hasEditPermission = usePermissionCheck().isHavingPermission(Permissions.writeMetric);
	const {
		isOpen: isEditCategoryModalOpen,
		onOpen: onEditCategoryModalOpen,
		onClose: onEditCategoryModalClose,
	} = useModal({ defaultIsOpen: false });

	const onEditCategoryClick = () => {
		reportEvent({ event: 'metric-catalog-category-edit-clicked' });
		onEditCategoryModalOpen();
	};

	const metricCards = useMemo(
		() =>
			metrics.map((metric: MetricMetadataV2, index) => {
				const withError = categoriesWithBrokenMetrics.some(
					(el) => el.categoryName === categoryName && el.metricNames.includes(metric?.name)
				);

				return (
					<GridItem
						display="flex"
						colSpan={{ base: 12, xs: 12, sm: 6, md: 4, lg: 3, xl: 3 }}
						key={`${categoryName}-${metric.name}-${metric.entity}`}
					>
						<Link href={getMetricHref(metric)} _hover={{ textDecoration: 'none' }} w={'100%'}>
							<MetricCardV2
								withError={withError}
								metricName={metric.name}
								categoryName={categoryName}
								metricDisplayName={metric.displayName}
								metricDescription={metric?.description ?? ''}
								metricEntity={metric.entity ?? ''}
								metricSource={metric.source}
								onClick={() => {
									reportEvent({
										event: 'metric-navigate-to',
										metaData: { metric: metric.name, categoryName },
									});
									handleMetricClick(metric);
								}}
								hidden={metric.hide}
								dataIntercomSequence={index + 1}
								metricId={metric?.id}
								metricCategoryId={categoryId || ''}
								isFullyDefined={metric.isFullyDefined}
								isNew={metric.isNew}
								cache={cache}
							/>
						</Link>
					</GridItem>
				);
			}),
		[
			metrics,
			categoryName,
			handleMetricClick,
			reportEvent,
			categoriesWithBrokenMetrics,
			getMetricHref,
			categoryId,
			cache,
		]
	);

	return (
		<>
			<Box id={getCategoryKey(metricCategory)} />
			<GridItem colSpan={12}>
				<div ref={ref}>
					<Flex flexDirection={'column'} gap={'6px'} className={classes.categoryTitleWrapper}>
						<Flex gap={'8px'} alignItems={'center'}>
							<Typography color={'gray.1000'} variant="DesktopH7Medium">
								{categoryName}
							</Typography>
							{hasEditPermission && (
								<Center
									p={'4px'}
									cursor={'pointer'}
									onClick={onEditCategoryClick}
									borderRadius={'4px'}
									mixBlendMode={'multiply'}
									_hover={{ backgroundColor: 'gray.200' }}
									opacity={0}
									className={classes.editCategoryButton}
								>
									<EditPencilSmall color={'gray.800'} />
								</Center>
							)}
						</Flex>

						{categoryDescription !== 'no description' && (
							<Typography color={'gray.1000'} variant="Paragraph12R">
								{categoryDescription}
							</Typography>
						)}
					</Flex>
				</div>
			</GridItem>
			{metricCards}
			<GridItem marginBottom={'14px'} colSpan={12}></GridItem>
			{isEditCategoryModalOpen && (
				<CreateEditCategoryModal
					isOpen={isEditCategoryModalOpen}
					onClose={onEditCategoryModalClose}
					category={metricCategory}
				/>
			)}
		</>
	);
}
