import Box from '@components/Box';
import Flex from '@components/Flex';
import Image from '@components/Image';
import { ICellRendererParams } from 'ag-grid-community';
import { useMemo } from 'react';
import { InitialsBadge } from 'src/common/components/InitialsBadge';
import { connectorsInfos } from 'src/common/components/TopNavigation/DataConnectorsModal/connectors';
import Typography from 'src/common/components/Typography';
import { ConnectorStatus } from 'src/generated/graphql';
import colors from 'src/style/colors';
import PLACEHOLDER_ICON from 'src/assets/icons/database-16.svg';
import { toLower } from 'lodash';
import { removeUnderscoresAndCapitalize } from 'src/normalize';

export function ConnectorLogoRenderer() {
	function Cell(params: ICellRendererParams) {
		const cellValue = params?.value || {};

		const badgeColorByStatus = useMemo(() => {
			switch (cellValue.status) {
				case ConnectorStatus.Broken:
					return 'red.700';
				case ConnectorStatus.Active:
					return 'green.500';
				case ConnectorStatus.Paused:
				case ConnectorStatus.Incomplete:
				case ConnectorStatus.Delayed:
					return 'yellow.500';
				default:
					return 'gray.500';
			}
		}, [cellValue.status]);

		const connectorsLogo = useMemo(
			() => connectorsInfos.find((info) => toLower(info.id) === toLower(cellValue.type)),
			[cellValue.type]
		);

		return (
			<Flex alignItems={'center'}>
				<Box position={'relative'}>
					<InitialsBadge size={'40px'} border={`1px solid ${colors.gray[300]}`} bgColor={'white'} />
					<Flex position={'absolute'} left={'50%'} top={'50%'} transform={'translate(-50%, -50%)'}>
						<Image src={connectorsLogo?.icon || PLACEHOLDER_ICON} maxWidth={'18px'} height={'18px'} width={'100%'} />
					</Flex>
					<Flex
						position={'absolute'}
						width={'8px'}
						height={'8px'}
						borderRadius={'50%'}
						right={'2px'}
						top={'2px'}
						backgroundColor={badgeColorByStatus}
					/>
				</Box>
				<Flex marginLeft={'16px'} flexDirection={'column'}>
					<Typography
						marginBottom={'4px'}
						variant={'DesktopH8Medium'}
						color="gray.1000"
						noOfLines={1}
						wordBreak="break-all"
					>
						{removeUnderscoresAndCapitalize(cellValue.sourceName)}
					</Typography>
					<Typography variant={'DesktopH10Regular'} color="gray.800" noOfLines={1} wordBreak="break-all">
						{removeUnderscoresAndCapitalize(cellValue.type)}
					</Typography>
				</Flex>
			</Flex>
		);
	}
	return Cell;
}
