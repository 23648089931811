import _ from 'lodash';
import { useCallback } from 'react';
import { FiltersV2Dict } from 'src/common/utils/MetricSearchParams';
import { SupportedDimensionTypes, SupportedOperators } from 'src/generated/graphql';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { useMetricPageSearchParams } from '../../hooks/useMetricPageSearchParams';
import { Filter, FilterV2 } from '../../utils/state.types';
import { removeDollarSigns } from '../FiltersAndBreakdown/NodeScheme/useCoreNodeScheme';

type Actions = {
	addFilters: (items: FilterV2[]) => void;
	editFilter: (newFilter: FilterV2, oldFilter: FilterV2) => void;
	removeFilter: (key: string, operator?: SupportedOperators) => void;
	removeAllFilters: VoidFunction;
};

function areFiltersIdentical(filter1: FiltersV2Dict, filter2: FiltersV2Dict) {
	return (
		filter1.key === filter2.key && filter1.operator === filter2.operator && _.isEqual(filter1.values, filter2.values)
	);
}

function removeIdenticalFilters(filters?: FiltersV2Dict[]) {
	return filters?.filter((filter, index, self) => {
		return index === self.findIndex((f) => areFiltersIdentical(f, filter));
	});
}

export function useFiltersV2(): [FilterV2[], Actions] {
	const { filters } = useMetricDerivedState();
	const { searchParams, setSearchParams } = useMetricPageSearchParams();

	const editFilter = useCallback(
		(newFilter: FilterV2, oldFilter: FilterV2) => {
			const currentParams = searchParams.filterByV2 ?? filters?.map<FiltersV2Dict>(convertToFilterV2);

			const newParams = currentParams.map<FiltersV2Dict>((filter) => {
				if (areFiltersIdentical(filter, oldFilter)) {
					return {
						key: newFilter.key,
						values: newFilter.values,
						type: newFilter.type,
						operator: newFilter.operator,
					};
				}
				return filter;
			});

			const dedupedParams = removeIdenticalFilters(newParams);

			setSearchParams({
				...searchParams,
				filterBy: undefined,
				filterByV2: dedupedParams,
				orderedComponents: undefined,
				sortOrder: undefined,
				tableColumnState: undefined,
			});
		},
		[filters, searchParams, setSearchParams]
	);

	const addFilters = useCallback(
		(items: FilterV2[]) => {
			const currentParams = searchParams.filterByV2 || [];

			const newItemsAsDict = items.map<FiltersV2Dict>((item) => {
				return {
					key: item.key,
					values: item.values,
					type: item.type,
					operator: item.operator,
				};
			});

			const newParams = removeIdenticalFilters([...currentParams, ...newItemsAsDict]);

			setSearchParams({
				...searchParams,
				filterByV2: newParams,
				orderedComponents: undefined,
				sortOrder: undefined,
				tableColumnState: undefined,
			});
		},
		[searchParams, setSearchParams]
	);

	const removeAllFilters = useCallback(() => {
		setSearchParams({ ...searchParams, filterBy: undefined, filterByV2: undefined });
	}, [searchParams, setSearchParams]);

	const removeFilter = useCallback(
		(key: string, operator?: SupportedOperators) => {
			const filtersV2Params = searchParams.filterByV2;
			const filtersV1 = { ...searchParams.filterBy };

			if (key in filtersV1 || removeDollarSigns(key) in filtersV1) {
				delete filtersV1[key];
				delete filtersV1[removeDollarSigns(key)];
			}

			const filtersV2 = filtersV2Params?.filter((item) => item.key !== key || item.operator !== operator);

			const hasParamsV1 = Object.keys(filtersV1).length;
			const hasParamsV2 = filtersV2?.length;
			setSearchParams({
				...searchParams,
				filterBy: hasParamsV1 ? filtersV1 : undefined,
				filterByV2: hasParamsV2 ? filtersV2 : undefined,
				orderedComponents: undefined,
				sortOrder: undefined,
				tableColumnState: undefined,
			});
		},
		[searchParams, setSearchParams]
	);

	const filtersV2 = filters.filter(isFilterV2);
	const filtersV1: FilterV2[] = filters.filter(isFilterV1).map(convertToFilterV2);

	return [[...filtersV2, ...filtersV1], { addFilters, removeFilter, removeAllFilters, editFilter }];
}

export function isFilterV2(item: FilterV2 | Filter): item is FilterV2 {
	return 'operator' in item;
}

export function isFilterV1(item: FilterV2 | Filter): item is Filter {
	return !isFilterV2(item);
}

export function convertToFilterV2(item: FilterV2 | Filter): FilterV2 {
	if (isFilterV2(item)) {
		return item;
	}
	return {
		key: item.key,
		label: item.label,
		values: item.values,
		type: SupportedDimensionTypes.String,
		operator: SupportedOperators.OneOfSensitive,
	};
}
