import { useSearchParams } from 'react-router-dom';
import { ModalHeader } from '@chakra-ui/react';
import Modal from '@components/Modal';
import Flex from '@components/Flex';
import Box from '@components/Box';
import Button from '@components/Button';
import { CloseLarge16 } from '@components//Icons';
import Typography from '@components//Typography';
import colors from 'src/style/colors';
import { showIntercom } from '@services/intercom';
import { useReportEvent } from '@services/analytics';
import { connectorsInfos } from './connectors';

export function DataConnectorsThankYouModal({ isOpen, onClose }: { isOpen: boolean; onClose: () => void }) {
	const { reportEvent } = useReportEvent({ feature: 'Connectors' });

	const [searchParams] = useSearchParams();
	const connectorId = searchParams.get('id');

	const modalHeader = (
		<ModalHeader color="gray.1000" padding={'16px'} paddingBottom={'0px'}>
			<Button variant="outline" isIconOnly onClick={onClose} size="inline" colorScheme="black">
				<CloseLarge16 />
			</Button>
		</ModalHeader>
	);

	const modalBody = (
		<Box textAlign={'center'} width={'100%'} padding={'24px 32px'} paddingBottom={'36px'}>
			<Typography color={colors.gray[1000]} variant="DesktopH5">
				Thanks for this.
			</Typography>
			<Typography marginTop={'6px'} color={colors.gray[900]} variant="Paragraph14R">
				One of our Data Team Experts will set up your connector and will be in touch with you in the next 24 hrs.
			</Typography>
		</Box>
	);

	const modalFooter = (
		<Flex paddingBottom={'48px'} gap={'12px'} justifyContent={'center'} alignContent={'center'}>
			<Button borderRadius={'8px'} size={'medium'} variant="solid" colorScheme={'blue'} onClick={onClose}>
				Got it!
			</Button>
			<Button
				borderRadius={'8px'}
				color={colors.gray[1000]}
				size={'medium'}
				variant="ghost"
				colorScheme={'gray'}
				onClick={() => {
					reportEvent({
						event: 'connectors-setup-contact-us',
						metaData: {
							name: connectorsInfos?.find((connector) => connector.id === connectorId)?.name,
						},
					});
					showIntercom();
				}}
			>
				Contact us
			</Button>
		</Flex>
	);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={true}
			maxWidth={'460px'}
			isCentered={true}
			borderRadius={'16px'}
			closeOnEsc={false}
		>
			{modalHeader}
			{modalBody}
			{modalFooter}
		</Modal>
	);
}
