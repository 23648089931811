import shadows from 'src/style/shadows';
import Button from '@components/Button';
import Flex from '@components/Flex';
import { useCallback } from 'react';
import { useReportEvent } from '@services/analytics';
import { DataConnectorInfo } from '@components/TopNavigation/DataConnectorsModal/types';
import { Exact, UserRequestSetupConnectorQuery } from 'src/generated/graphql';
import { LazyQueryExecFunction } from '@apollo/client/react/types/types';

export function DataConnectorsModalFooter({
	onModalClose,
	selectedConnector,
	requestSetupConnectorLazyQuery,
	onNextButtonClick,
}: {
	onModalClose: () => void;
	selectedConnector: DataConnectorInfo | null;
	requestSetupConnectorLazyQuery: LazyQueryExecFunction<
		UserRequestSetupConnectorQuery,
		Exact<{ connectorName: string }>
	>;
	onNextButtonClick: VoidFunction;
}) {
	const { reportEvent } = useReportEvent();

	const isNextButtonDisabled = selectedConnector == null;

	const innerOnNextButtonClick = useCallback(async () => {
		if (!selectedConnector?.name) return;

		await requestSetupConnectorLazyQuery({ variables: { connectorName: selectedConnector.name } });
		onNextButtonClick();

		onModalClose();
	}, [onModalClose, onNextButtonClick, requestSetupConnectorLazyQuery, selectedConnector]);

	return (
		<Flex
			padding={'16px 24px'}
			justifyContent={'space-between'}
			alignItems={'center'}
			alignSelf={'stretch'}
			shadow={shadows.borderTop}
		>
			<Button colorScheme={'black'} onClick={onModalClose} variant={'outline'}>
				Cancel
			</Button>
			<Button
				borderRadius={'8px'}
				colorScheme={isNextButtonDisabled ? 'gray' : 'blue'}
				variant={'solid'}
				onClick={async () => {
					await innerOnNextButtonClick();
					reportEvent({
						event: 'connectors-add-connector-select',
						metaData: {
							name: selectedConnector?.name,
							category: selectedConnector?.type,
						},
					});
				}}
				isDisabled={isNextButtonDisabled}
			>
				Next
			</Button>
		</Flex>
	);
}
