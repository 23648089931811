import { useAtom } from 'jotai';
import { useCallback } from 'react';
import { atom } from 'jotai';

export const AskAIBusyCounterAtom = atom(0);
const AskAIBusyCounterScope = Symbol();

export function useAskAIBusyState(): [boolean, (running: boolean) => void] {
	const [busyCounter, setBusyCounter] = useAtom(AskAIBusyCounterAtom, AskAIBusyCounterScope);

	const setAskAIBusyState = useCallback(
		(busy: boolean) => {
			const value = busy ? 1 : -1;
			setBusyCounter((counter) => Math.max(counter + value, 0));
		},
		[setBusyCounter]
	);

	return [busyCounter > 0, setAskAIBusyState];
}
