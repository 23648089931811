import Box from '@components/Box';
import Flex from '@components/Flex';
import useFeatureFlag from '@hooks/stores/useFeatureFlag';
import { useCallback, useMemo, useRef, useState } from 'react';
import Page from 'src/layout/Page';
import { MetricCatalogBody } from 'src/pages/MetricCatalog/MetricCatalogBody';
import { MetricCatalogBodyV2 } from 'src/pages/MetricCatalog/MetricCatalogBodyV2';
import { CategoryV2, MetricMetadataV2 } from 'src/types/metric';
import { MetricCatalogHeader } from './MetricCatalogHeader';
import TemplateOnboardingModal from './TemplateOnboardingModal/TemplateOnboardingModal';
import { InstallTemplateMissingPermissionsModal } from './TemplateOnboardingModal/components/InstallTemplateMissingPermissionsModal';

import LeftExpandCollapsePanel from 'src/common/components/LeftExpandCollapsePanel';
import Typography from 'src/common/components/Typography';

import NavigationDrawer from 'src/layout/Menu/NavigationDrawer';
import { MetricCatalogHeaderV2 } from './MetricCatalogHeaderV2';
import { SkeletonDrawer, SkeletonMetricCatalogBody } from './SkeletonComponents';
import { useMetricCategories } from './hooks/useMetricCategories';
import { CheckForTerm, getCategoryKey } from './utils';

import filter from 'lodash/filter';
import flatMap from 'lodash/flatMap';
import { CellMeasurerCache, List } from 'react-virtualized';
import NavigationDrawerV2 from 'src/layout/Menu/NavigationDrawer/NavigationDrawerV2';
import { useTemplateOnboardingModal } from 'src/pages/MetricCatalog/TemplateOnboardingModal/hooks/useTemplateOnboardingModal';
import shadows from 'src/style/shadows';
import { useMetricCategoriesV2 } from './hooks/useMetricCategoriesV2';

export function MetricCatalogGuard() {
	const isRunningSightfullV2 = useFeatureFlag('pulse.sightfull2.enable');
	const [searchTerm, setSearchTerm] = useState('');

	return (
		<Page page="Metric Catalog">
			{isRunningSightfullV2 ? (
				<MetricCatalogV2 searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
			) : (
				<MetricCatalog searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
			)}
		</Page>
	);
}

export function MetricCatalogV2({
	searchTerm,
	setSearchTerm,
}: {
	searchTerm: string;
	setSearchTerm: (term: string) => void;
}) {
	const pageHorizontalPadding = '32px';
	const pageMinWidth = '600px';
	const metricHeaderRef = useRef<HTMLDivElement>(null);
	const [metricCategories, toggleShouldDropUncategorized, isLoading] = useMetricCategoriesV2();
	const listRef = useRef<List>(null);

	const [shouldShowHiddenMetrics, setShouldShowHiddenMetrics] = useState(false);

	const doesCategoryContainsSearchTerm = (category: CategoryV2, searchTerm: string) =>
		CheckForTerm(category.name, searchTerm) || CheckForTerm(category.description, searchTerm);

	const doesMetricContainsSearchTerm = (metric: MetricMetadataV2, searchTerm: string) =>
		CheckForTerm(metric.displayName || '', searchTerm) ||
		CheckForTerm(metric.name, searchTerm) ||
		CheckForTerm(metric.oneliner || '', searchTerm);

	const shouldHideMetric = useCallback(
		(metric: MetricMetadataV2) => !shouldShowHiddenMetrics && metric.hide,
		[shouldShowHiddenMetrics]
	);

	const categoriesWithFilteredMetrics = useMemo(
		() =>
			metricCategories.reduce((acc: CategoryV2[], category: CategoryV2) => {
				if (doesCategoryContainsSearchTerm(category, searchTerm)) {
					acc.push({
						...category,
						metrics: category.metrics.filter((metric) => !shouldHideMetric(metric)),
					});
					return acc;
				}

				const categorySearchedMetrics = category.metrics.filter((metric) => {
					if (shouldHideMetric(metric)) return false;

					return doesMetricContainsSearchTerm(metric, searchTerm);
				});

				if (categorySearchedMetrics.length > 0) {
					acc.push({
						...category,
						metrics: categorySearchedMetrics,
					});
				}

				return acc;
			}, []),
		[metricCategories, searchTerm, shouldHideMetric]
	);

	const pendingSetupMetrics = useMemo(() => {
		return flatMap(categoriesWithFilteredMetrics, (category) =>
			filter(category.metrics, (metric) => !metric.isFullyDefined)
		);
	}, [categoriesWithFilteredMetrics]);

	const {
		referralPageName,
		templateName,
		templatesTag,
		isTemplateOnboardingModalOpen,
		onTemplateOnboardingModalClose,
		isInstallTemplateMissingPermissionsModal,
		onInstallTemplateMissingPermissionsModalClose,
	} = useTemplateOnboardingModal();
	const isAddTemplateEnabled = useFeatureFlag('pulse.sightfull2.addTemplate.enable');

	function CatalogTitle({ isShowingFullSize }: { isShowingFullSize: boolean }) {
		return (
			<Flex
				justifyContent={'space-between'}
				alignItems={'center'}
				paddingLeft="24px"
				paddingRight="14px"
				height="60px"
				style={{
					boxShadow: shadows.borderBottom,
					visibility: isShowingFullSize ? 'visible' : 'hidden',
					opacity: isShowingFullSize ? 1 : 0,
					transition: '0.1s',
					transform: isShowingFullSize ? 'scale(1)' : 'scale(0.9)',
				}}
			>
				<Typography
					onClick={(e) => e.detail == 2 && toggleShouldDropUncategorized()}
					color={'gray.1000'}
					variant="DesktopH6Medium"
				>
					Metrics
				</Typography>
			</Flex>
		);
	}

	const scrollTo = (index: number) => {
		if (index === 0 || index === -1) {
			listRef?.current?.scrollToPosition(0);
		} else {
			listRef?.current?.scrollToRow(index);
		}
		listRef?.current?.recomputeRowHeights();
	};

	const scrollToSelectedCategory = useCallback(
		(categoryKey: string) => {
			const element = document?.getElementById(categoryKey);
			if (element) {
				element?.scrollIntoView({ behavior: 'smooth' });
			} else {
				const index = metricCategories.findIndex((el) => getCategoryKey(el) === categoryKey);
				scrollTo(index);
				setTimeout(() => scrollToSelectedCategory(categoryKey), 100); // should call this way because of the library usage: https://github.com/bvaughn/react-virtualized/issues/995
			}
		},
		[metricCategories]
	);

	const cache = useRef(
		new CellMeasurerCache({
			fixedWidth: true,
			defaultHeight: window.innerHeight,
		})
	);

	const clearMetricCardsCache = useCallback(() => cache?.current.clearAll(), []);

	const catalogBody = useMemo(
		() => (
			<MetricCatalogBodyV2
				cache={cache.current}
				listRef={listRef}
				categoriesWithFilteredMetrics={categoriesWithFilteredMetrics}
				searchTerm={searchTerm}
				pendingSetupMetrics={searchTerm ? [] : pendingSetupMetrics}
			/>
		),
		[categoriesWithFilteredMetrics, searchTerm, pendingSetupMetrics]
	);

	return (
		<>
			<Flex h="100%" maxWidth={'100%'} maxH="inherit" flexGrow={1} w="100%" overflow="hidden" id={metricCatalogBodyId}>
				<LeftExpandCollapsePanel
					isResizeBlocked
					width={272}
					renderItem={(isShowingFullSize: boolean) =>
						isLoading ? (
							isShowingFullSize && <SkeletonDrawer />
						) : (
							<>
								<CatalogTitle isShowingFullSize={isShowingFullSize} />
								<NavigationDrawerV2
									categoriesWithFilteredMetrics={categoriesWithFilteredMetrics}
									scrollToSelectedCategory={scrollToSelectedCategory}
									isShowingFullSize={isShowingFullSize}
									pendingSetupMetrics={pendingSetupMetrics}
								/>
							</>
						)
					}
				/>
				<Flex width={'100%'} flexDir={'column'}>
					<div ref={metricHeaderRef}>
						<MetricCatalogHeaderV2
							isLoading={isLoading}
							onSearchTermChange={(term: string) => {
								setSearchTerm(term);
								clearMetricCardsCache();
								setTimeout(() => listRef?.current?.scrollToPosition(0), 0);
							}}
							paddingHorizontal={pageHorizontalPadding}
							minWidth={pageMinWidth}
							shouldShowHiddenMetrics={shouldShowHiddenMetrics}
							setShouldShowHiddenMetrics={(hidden: boolean) => {
								setShouldShowHiddenMetrics(hidden);
								clearMetricCardsCache();
							}}
						/>
					</div>

					<Box
						maxH="inherit"
						overflow={'hidden'}
						pl={pageHorizontalPadding}
						h={'100%'}
						w="100%"
						minW={pageMinWidth}
						data-intercom-area={'catalog'}
						data-intercom-type={'main'}
						data-intercom-target={'main'}
					>
						{isLoading ? <SkeletonMetricCatalogBody isRunningSightfullV2 /> : catalogBody}
					</Box>
				</Flex>
			</Flex>

			{isAddTemplateEnabled && (
				<>
					<TemplateOnboardingModal
						isOpen={isTemplateOnboardingModalOpen}
						onClose={onTemplateOnboardingModalClose}
						referralPageName={referralPageName}
						templateName={templateName}
						templatesTag={templatesTag}
						clearMetricCardsCache={clearMetricCardsCache}
					/>

					<InstallTemplateMissingPermissionsModal
						isOpen={isInstallTemplateMissingPermissionsModal}
						onClose={onInstallTemplateMissingPermissionsModalClose}
						templateName={templateName}
					/>
				</>
			)}
		</>
	);
}

export function MetricCatalog({
	searchTerm,
	setSearchTerm,
}: {
	searchTerm: string;
	setSearchTerm: (term: string) => void;
}) {
	const pageHorizontalPadding = '32px';
	const pageMinWidth = '600px';
	const metricHeaderRef = useRef<HTMLDivElement>(null);
	const [metricCategories, toggleShouldDropUncategorized, isLoading] = useMetricCategories();

	const [activeCategory, setActiveCategory] = useState('');
	function scrollToSelectedCategory() {
		if (activeCategory) {
			document?.getElementById(activeCategory)?.scrollIntoView({ behavior: 'smooth' });
		}
	}

	//TODO: Allow show all metrics with Feature Flag
	scrollToSelectedCategory();

	return (
		<Flex height="100%" flexDir={'column'}>
			{/* TODO: margins and gaps with breakpoints */}
			<div ref={metricHeaderRef}>
				<MetricCatalogHeader
					searchTerm={searchTerm}
					onSearchTermChange={setSearchTerm}
					onHeaderDoubleClick={toggleShouldDropUncategorized}
					paddingHorizontal={pageHorizontalPadding}
					minWidth={pageMinWidth}
				/>
			</div>
			<Flex h="100%" maxWidth={'100%'} maxH="inherit" flexGrow={1} w="100%" overflow="hidden" id={metricCatalogBodyId}>
				<LeftExpandCollapsePanel
					isResizeBlocked
					width={272}
					renderItem={(isShowingFullSize: boolean) => (
						<NavigationDrawer
							isShowingFullSize={isShowingFullSize}
							activeCategory={activeCategory}
							handleCategoryClick={(category: string) => setActiveCategory(category)}
							currentDrawerView="metrics"
						/>
					)}
				/>

				<Box
					maxH="inherit"
					overflow={'scroll'}
					px={pageHorizontalPadding}
					w="100%"
					minW={pageMinWidth}
					data-intercom-area={'catalog'}
					data-intercom-type={'main'}
					data-intercom-target={'main'}
				>
					{isLoading ? (
						<SkeletonMetricCatalogBody />
					) : (
						<MetricCatalogBody
							activeCategory={activeCategory}
							handleCategoryClick={(category: string) => setActiveCategory(category)}
							metricCategories={metricCategories}
							searchTerm={searchTerm}
							headerTopHeight={metricHeaderRef.current?.getBoundingClientRect().top ?? 0}
						/>
					)}
				</Box>
			</Flex>
		</Flex>
	);
}

export const metricCatalogBodyId = 'MetricCatalogBody';
