import Box from '@components/Box';
import Divider from '@components/Divider';
import { FilterOutline16 } from '@components/Icons';
import { AddDrillDownCard } from '@components/LeftExpandCollapsePanel/Drilldown/AddDrillDownCard';
import Typography from '@components/Typography';
import { MetricPagePath } from '@pages/MetricPage/pageRoutesPaths';
import { useReportEvent } from '@services/analytics';
import { FilterDrilldownCard2 } from 'src/common/components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/FilterDrilldownCard2';
import { useAddMetricFilter2 } from 'src/common/components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/useAddMetricFilter2';
import * as CONSTS from 'src/pages/MetricPage/components/FiltersAndBreakdown/consts';
import { useMetricDerivedState } from '../../hooks/useMetricDerivedState';
import { FilterV2 } from '../../utils/state.types';
import classes from '../InvestigatePanel/InvestigatePanel.module.scss';
import { useFiltersV2 } from '../InvestigatePanel/useFiltersV2';
import { Title } from '../PanelTitle/Title';
import { convertModalResultToValidCoreValues } from './NodeScheme/useCoreNodeScheme';
import { OnModalOpenType } from './types';

export function FiltersPanel2({
	isShowingFullSize,
	isDisabled,
	onModalOpen,
}: {
	isShowingFullSize: boolean;
	isDisabled?: boolean;
	onModalOpen: OnModalOpenType;
}) {
	const [filters, { removeAllFilters, removeFilter }] = useFiltersV2();
	const addFilter = useAddMetricFilter2();
	const { objectsTypes } = useMetricDerivedState();
	const { wrapWithReport, reportEvent } = useReportEvent();
	const isAddFilterButtonShown = !isDisabled && filters.every((filter) => filter.values.length > 0);
	const { pathname } = window.location;
	const isMetricPage = pathname.includes(`/${MetricPagePath}/`);
	const eventMetadata = {
		feature: 'filters',
		page: isMetricPage ? 'Metric Page' : 'Signal Page',
		application: 'metric investigation',
	};

	const onModalClose = (result: any) => {
		const coreFilterResult = convertModalResultToValidCoreValues(result, objectsTypes[0]);

		const filterV2 = coreFilterResult
			.map((filter) => {
				const value = filter.value == undefined ? [] : [filter.value];
				return {
					label: filter.label || '',
					key: filter.key,
					values: value,
				};
			})
			.reduce((acc, filter) => {
				if ('values' in acc) {
					return {
						...acc,
						values: [...acc.values, ...filter.values],
					};
				}
				return filter;
			}, {} as Omit<FilterV2, 'type' | 'operator'>);

		addFilter(filterV2);
	};

	return (
		<>
			<Box whiteSpace="nowrap" height={isShowingFullSize ? 'min-content' : '0'} opacity={isShowingFullSize ? '1' : '0'}>
				<Title
					onAction={() => {
						reportEvent({ event: 'filters-remove-all-filters', metaData: eventMetadata });
						removeAllFilters();
					}}
					text={'Filters'}
					showAction={!!filters?.length}
				/>
				{!isDisabled &&
					filters.map((filter) => {
						const isEditorAutoOpened = filter.values.length === 0 && isShowingFullSize;

						return (
							<FilterDrilldownCard2
								key={filter.key + filter.values.join()}
								filter={filter}
								onRemove={wrapWithReport(
									() => removeFilter(filter.key, filter.operator),
									'metric-filters-delete-clicked'
								)}
								isEditorAutoOpened={isEditorAutoOpened}
								eventMetadata={eventMetadata}
							/>
						);
					})}
				{isAddFilterButtonShown && (
					<AddDrillDownCard
						isDisabled={isDisabled}
						type={CONSTS.FILTER_MODEL_TYPE}
						onClick={wrapWithReport(
							() => onModalOpen(CONSTS.FILTER_MODEL_TYPE, onModalClose),
							'metric-filters-clicked'
						)}
						dataIntercomTarget={'Add filter'}
					/>
				)}
			</Box>
			{!isShowingFullSize && (
				<Box
					display="flex"
					width="32px"
					height="32px"
					paddingTop={'15px'}
					position="relative"
					justifyContent="center"
					alignItems="center"
				>
					<FilterOutline16 />
					{!!filters.length && (
						<Box className={classes.badgeNumber}>
							<Typography variant="Paragraph12M">{filters.length}</Typography>
						</Box>
					)}
				</Box>
			)}
			<Box paddingY={isShowingFullSize ? '24px' : '8px'}>
				<Divider direction={'horizontal'}></Divider>
			</Box>
		</>
	);
}
