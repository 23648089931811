import { InputStringEditor } from '../../Editors/InputStringEditor';
import { ValueEditorProps } from './ValueEditorByFilter';

export function FilterInputStringEditor({ filter, onApply, prefixComponent }: ValueEditorProps) {
	return (
		<InputStringEditor
			prefixComponent={prefixComponent}
			value={filter.values[0]?.toString() || ''}
			onApply={(value: string) => {
				onApply([value]);
			}}
			type="text"
		/>
	);
}
