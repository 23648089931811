import { ConnectorStatus } from 'src/generated/graphql';
import { useEnvironment } from 'src/stores/environment';
import { useMemo } from 'react';
export type ConnectorMockData = {
	__typename?: 'ConnectorDetails';
	name: string;
	connectorStatus: ConnectorStatus;
	createdAt: string;
	type: string;
	createdBy: string;
	lastSuccessfulSync?: string | null | undefined;
};
function createMockConnectorData({
	name,
	type,
	createdBy,
	createdAt,
	lastSuccessfulSync,
	connectorStatus,
}: {
	name: string;
	type: string;
	createdBy: string;
	createdAt: string;
	lastSuccessfulSync: string;
	connectorStatus: ConnectorStatus;
}): ConnectorMockData {
	return {
		__typename: 'ConnectorDetails',
		name,
		type,
		connectorStatus,
		createdAt,
		lastSuccessfulSync,
		createdBy,
	};
}

function getRandomWorkHourTime(date: string): string {
	const baseDate = new Date(date);
	const hour = Math.floor(Math.random() * 8) + 9;
	const minute = Math.floor(Math.random() * 60);
	const second = Math.floor(Math.random() * 60);

	baseDate.setHours(hour, minute, second, 0);
	return baseDate.toISOString();
}

const getOneHourAgoString = () => {
	const oneHourAgo = new Date();
	oneHourAgo.setHours(oneHourAgo.getHours() - 1);
	oneHourAgo.setMinutes(2);
	return oneHourAgo.toISOString();
};

export function useMockData() {
	const tenantIdToMockData: {
		[key: number]: ConnectorMockData[];
	} = useMemo(() => {
		return {
			536936477: [
				createMockConnectorData({
					name: 'Salesforce',
					type: 'salesforce',
					createdBy: 'Malcolm Johnson',
					createdAt: getRandomWorkHourTime('2024-05-06'),
					lastSuccessfulSync: getOneHourAgoString(),
					connectorStatus: ConnectorStatus.Active,
				}),
				createMockConnectorData({
					name: 'Linkedin',
					type: 'linkedin_ads',
					createdBy: 'Malcolm Johnson',
					createdAt: getRandomWorkHourTime('2024-05-06'),
					lastSuccessfulSync: getOneHourAgoString(),
					connectorStatus: ConnectorStatus.Active,
				}),
				createMockConnectorData({
					name: 'Google Analytics',
					type: 'google_analytics',
					createdBy: 'Malcolm Johnson',
					createdAt: getRandomWorkHourTime('2024-05-06'),
					lastSuccessfulSync: getOneHourAgoString(),
					connectorStatus: ConnectorStatus.Active,
				}),
				createMockConnectorData({
					name: 'Campaign Costs',
					type: 'google_sheets',
					createdBy: 'Malcolm Johnson',
					createdAt: getRandomWorkHourTime('2024-05-06'),
					lastSuccessfulSync: getOneHourAgoString(),
					connectorStatus: ConnectorStatus.Active,
				}),
			],
			536936473: [
				createMockConnectorData({
					name: 'Salesforce',
					type: 'salesforce',
					createdBy: 'Noam Liran',
					createdAt: getRandomWorkHourTime('2024-01-18'),
					lastSuccessfulSync: getOneHourAgoString(),
					connectorStatus: ConnectorStatus.Active,
				}),
				createMockConnectorData({
					name: 'Linkedin',
					type: 'linkedin_ads',
					createdBy: 'Noam Liran',
					createdAt: getRandomWorkHourTime('2024-01-18'),
					lastSuccessfulSync: getOneHourAgoString(),
					connectorStatus: ConnectorStatus.Active,
				}),
				createMockConnectorData({
					name: 'Google Analytics',
					type: 'google_analytics',
					createdBy: 'Noam Liran',
					createdAt: getRandomWorkHourTime('2024-01-18'),
					lastSuccessfulSync: getOneHourAgoString(),
					connectorStatus: ConnectorStatus.Active,
				}),
				createMockConnectorData({
					name: 'Campaign Costs',
					type: 'google_sheets',
					createdBy: 'Noam Liran',
					createdAt: getRandomWorkHourTime('2024-01-18'),
					lastSuccessfulSync: getOneHourAgoString(),
					connectorStatus: ConnectorStatus.Active,
				}),
			],
			15: [
				createMockConnectorData({
					name: 'Salesforce',
					type: 'salesforce',
					createdBy: 'Lior Dvora',
					createdAt: getRandomWorkHourTime('2024-01-18'),
					lastSuccessfulSync: getOneHourAgoString(),
					connectorStatus: ConnectorStatus.Active,
				}),
				createMockConnectorData({
					name: 'Google Sheets',
					type: 'google_sheets',
					createdBy: 'Lior Dvora',
					createdAt: getRandomWorkHourTime('2024-01-18'),
					lastSuccessfulSync: getOneHourAgoString(),
					connectorStatus: ConnectorStatus.Active,
				}),
			],
		};
	}, []);
	const { tenant: tenantIdNumber } = useEnvironment();
	const isMockTenant = useMemo(
		() => Object.hasOwn(tenantIdToMockData, tenantIdNumber),
		[tenantIdNumber, tenantIdToMockData]
	);
	const mockData = isMockTenant ? tenantIdToMockData[tenantIdNumber] ?? [] : [];

	return { isMockTenant, mockData };
}
