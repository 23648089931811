import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { Plus16 } from '@components/Icons';
import { useModal } from '@hooks/ui/useModal';

import { useCallback, useEffect, useState } from 'react';
import { AskAIInput } from '../AskAIInput';
import { AskAIModalItem } from '../AskAIModalItem';
import { AskAIFooter } from './AskAIFooter';
import { AskAIInputEcho } from './AskAIInputEcho';
import { AskAISearchData } from './AskAISearchData';

import { useKeyPress } from '@hooks/interaction/useKeyPress';
import useEntitySearch from '@hooks/stores/useEntityState';
import { useSearchOptions } from '@layout/Menu/SearchBox/useSearchOptions';
import { SearchResultsPath } from '@pages/SearchResultsPage';
import useNavigation from '@services/useNavigation';
import { useAskAIChatMessages } from '../hooks/useAskAIChatMessages';
import { useReportAIEvent } from '../hooks/useReportAIEvent';
import { AskAICreateNewMetricPopover } from '../AskAICreateNewMetricPopover';

type AskAIMainPageProps = {
	onSubmit: (askingAI: boolean) => void;
	onClose: () => void;
};

type SelectableItem = {
	component: JSX.Element;
	action: (userPrompt: string) => void;
	header?: string;
};

type SelectableItemProps = {
	inputValue: string;
	onItemClicked: (askingAI: boolean) => void;
};

function useInputEchoItem({ inputValue, onItemClicked }: SelectableItemProps): SelectableItem {
	const { reportAskAIEvent } = useReportAIEvent();
	const { addNewUserPrompt } = useAskAIChatMessages();

	const onPerformAskAI = useCallback(
		(userPrompt: string) => {
			reportAskAIEvent({
				event: 'ask-ai-query',
				metaData: { queryText: userPrompt },
			});
			addNewUserPrompt(userPrompt);
			onItemClicked(true);
		},
		[addNewUserPrompt, onItemClicked, reportAskAIEvent]
	);

	return {
		action: onPerformAskAI,
		component: <AskAIInputEcho value={inputValue} key={'echo'} />,
	};
}

function useSearchDataItem({ inputValue, onItemClicked }: SelectableItemProps): SelectableItem {
	const { reportAskAIEvent } = useReportAIEvent();

	const { v2Options: searchOptions } = useSearchOptions(true);
	const { navigate } = useNavigation();
	const [{ selectedCategory }, { reset }] = useEntitySearch();

	const onSearch = useCallback(
		(userPrompt: string) => {
			const category = selectedCategory.value != '' ? selectedCategory : searchOptions[0];
			if (!category.value) return;

			reportAskAIEvent({
				event: 'global-search-enter',
				metaData: { category: category.value, searchTerm: userPrompt },
			});
			const searchPath = `${SearchResultsPath}/${encodeURIComponent(userPrompt)}/${category.value}`;
			navigate({ path: searchPath });
			reset();
			onItemClicked(false);
		},
		[navigate, onItemClicked, reportAskAIEvent, reset, searchOptions, selectedCategory]
	);

	return {
		action: onSearch,
		header: 'Search in your data',
		component: <AskAISearchData value={inputValue} key={'search'} searchOptions={searchOptions} />,
	};
}

function useCreateNewMetricItem({ inputValue, onItemClicked }: SelectableItemProps): SelectableItem {
	const { reportAskAIEvent } = useReportAIEvent();
	const { isOpen, onOpen, onClose } = useModal();

	const onMetricTypeClick = useCallback(() => {
		onItemClicked(false);
	}, [onItemClicked]);

	const onCreateNewMetric = useCallback(
		(userPrompt: string) => {
			reportAskAIEvent({
				event: 'create-new-metric',
				metaData: { queryText: userPrompt },
			});
			onOpen();
		},
		[onOpen, reportAskAIEvent]
	);

	return {
		action: onCreateNewMetric,
		component: (
			<AskAICreateNewMetricPopover
				prompt={inputValue}
				createInNewTab={false}
				onMetricTypeClick={onMetricTypeClick}
				isSelectMetricTypeOpen={isOpen}
				onSelectMetricTypeClose={onClose}
				key={'create-new-metric'}
			>
				<Flex alignItems={'center'}>
					<Plus16 />
					<Typography
						variant={'DesktopH8Regular'}
						textAlign="start"
						paddingLeft={'12px'}
						testId={`ask-ai-create-new-metric`}
					>
						Create new metric to answer “{inputValue}”
					</Typography>
				</Flex>
			</AskAICreateNewMetricPopover>
		),
	};
}

function useSelectableItems({ inputValue, onItemClicked }: SelectableItemProps): SelectableItem[] {
	const selectableItems = [
		useInputEchoItem({ inputValue, onItemClicked }),
		useSearchDataItem({ inputValue, onItemClicked }),
		useCreateNewMetricItem({ inputValue, onItemClicked }),
	];
	if (inputValue.length === 0) return [];
	return selectableItems;
}

export function AskAIMainPage({ onSubmit }: AskAIMainPageProps) {
	const [inputValue, setInputValue] = useState('');
	const [selectedIndex, setSelectedIndex] = useState(0);

	const onItemClicked = useCallback(
		(askingAI: boolean) => {
			setInputValue('');
			onSubmit(askingAI);
		},
		[onSubmit]
	);

	const selectableItems = useSelectableItems({ inputValue, onItemClicked });

	useEffect(() => {
		if (inputValue === '') {
			setSelectedIndex(0);
		}
	}, [inputValue, setSelectedIndex]);

	const onInputChange = useCallback(
		(value: string) => {
			setInputValue(value);
		},
		[setInputValue]
	);

	const onSubmitInput = useCallback(
		(userPrompt: string) => {
			if (userPrompt.length > 0) {
				selectableItems[selectedIndex]?.action?.call(null, userPrompt);
			}
		},
		[selectableItems, selectedIndex]
	);

	const upDownEvent = useCallback(
		(up: boolean) => {
			const numItems = selectableItems.length;
			const nextIndex = up ? selectedIndex - 1 : selectedIndex + 1;
			setSelectedIndex(Math.abs(nextIndex) % numItems);
		},
		[selectableItems.length, selectedIndex]
	);

	const onKeyUp = useCallback(() => upDownEvent(true), [upDownEvent]);
	const onKeyDown = useCallback(() => upDownEvent(false), [upDownEvent]);
	const onEnter = useCallback(() => {
		onSubmitInput(inputValue);
	}, [inputValue, onSubmitInput]);

	useKeyPress(['ArrowUp'], onKeyUp);
	useKeyPress(['ArrowDown'], onKeyDown);
	useKeyPress(['Enter'], onEnter);

	const onHoverSelectableItem = useCallback((index?: number) => {
		if (index !== undefined) setSelectedIndex(index);
	}, []);

	const onClickSelectableItem = useCallback(() => {
		onEnter();
	}, [onEnter]);

	return (
		<Flex direction={'column'} color={'gray.1000'} data-testid={'ask-ai-main-page'} data-selected-index={selectedIndex}>
			<AskAIModalItem selectable={false} padding={'7px 4px'}>
				<AskAIInput
					placeholder={'Search or ask anything with Sightfull AI'}
					onChange={onInputChange}
					isErasable={true}
					hasBorder={false}
					reportEvent={'ask-ai-main-query'}
					reportFeature={'Ask AI'}
				/>
			</AskAIModalItem>
			{inputValue
				? selectableItems.map((selectableItem, index) => (
						<AskAIModalItem
							borderTop={true}
							selectable={true}
							selectableIndex={index}
							selected={selectedIndex === index}
							key={index}
							header={selectableItem.header}
							onHover={onHoverSelectableItem}
							onClick={onClickSelectableItem}
						>
							{selectableItem.component}
						</AskAIModalItem>
				  ))
				: null}
			{inputValue ? (
				<AskAIModalItem borderTop={true} backgroundColor={'gray.200'} selectable={false}>
					<AskAIFooter />
				</AskAIModalItem>
			) : null}
		</Flex>
	);
}
