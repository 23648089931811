import Box from '@components/Box';
import Flex from '@components/Flex';
import { GridTableBody } from 'src/common/components/GridTable';
import { connectorsTableMetadata } from '../../utils/constants';
import useTenantConfig from 'src/common/hooks/stores/useTenantConfig';
import { useCallback, useMemo, useState } from 'react';
import Typography from 'src/common/components/Typography';
import pluralize from 'pluralize';
import Tooltip from 'src/common/components/Tooltip';
import Button from 'src/common/components/Button';
import { Search16 } from 'src/common/components/Icons';
import Input from 'src/common/components/Input';
import { useReportEvent } from 'src/services/analytics';
import { checkForMatch } from 'src/common/utils/format';
import { useKeyPress } from 'src/common/hooks/interaction/useKeyPress';
import { serializeConnectorsTableColumnDef, serializeConnectorsTableRowDef } from '../../utils/utils';
import { useListConnectorsQuery } from 'src/generated/graphql';
import { SkeletonTableLayout } from '../SkeletonComponents/SkeletonTableLayout';
import { ConnectorMockData, useMockData } from '@pages/ConnectorsPage/components/ConnectorsTable/useMockData';

const MAX_ROWS_NUMBER = 7;

export const ConnectorsTable = () => {
	const tenantConfig = useTenantConfig();
	const [searchValue, setSearchValue] = useState<string | undefined>();
	const { reportEvent } = useReportEvent();
	const { isMockTenant, mockData } = useMockData();
	const { data: connectorsArray, loading: isLoadingConnectorsList } = useListConnectorsQuery({
		fetchPolicy: 'no-cache',
		skip: isMockTenant,
	});

	const connectorsList: ConnectorMockData[] = useMemo(() => {
		return (isMockTenant ? mockData : connectorsArray?.listConnectors?.connectors) || [];
	}, [connectorsArray?.listConnectors?.connectors, isMockTenant, mockData]);

	const columnDefs = useMemo(
		() => serializeConnectorsTableColumnDef(connectorsTableMetadata, tenantConfig) || [],
		[tenantConfig]
	);

	const rowData = useMemo(() => {
		const checkAllProps = (obj: unknown): boolean => {
			if (typeof obj === 'object' && obj !== null) {
				return Object.values(obj).some((prop) => {
					if (typeof prop === 'string') return checkForMatch({ subject: prop, value: searchValue || '' });
					if (typeof prop === 'object' && prop !== null) return checkAllProps(prop);
					return false;
				});
			}
			return false;
		};

		return serializeConnectorsTableRowDef(connectorsList)?.filter((connector) => checkAllProps(connector));
	}, [connectorsList, searchValue]);

	const isActiveSearch = useMemo(() => searchValue !== undefined, [searchValue]);

	const onCloseSearchInput = () => !searchValue && onSearch?.(undefined);

	useKeyPress(['Escape'], onCloseSearchInput);

	const onSearch = useCallback(
		(val?: string) => {
			setSearchValue(val);
			reportEvent({
				event: 'connectors-object-search-type',
				metaData: { objectType: 'connectors', searchTerm: val },
			});
		},
		[reportEvent]
	);

	const SearchBar = () => (
		<Flex gap={'16px'} alignItems={'center'} marginBottom={'20px'}>
			{isActiveSearch ? (
				<Flex maxW={'280px'} width={'100%'} borderRadius={'8px'}>
					<Input
						borderRadius="4px"
						borderColor="gray.300"
						isErasable={!!searchValue}
						autoFocus
						size="xs"
						leftComponent={<Search16 />}
						placeholder="Search"
						value={searchValue}
						onEnter={onCloseSearchInput}
						onBlur={onCloseSearchInput}
						onChange={(val: string) => onSearch?.(val)}
					/>
				</Flex>
			) : (
				<>
					<Tooltip label="Search" size="md" variant="fluid" background="black" hasArrow={true} marginTop="8px">
						<Button isIconOnly variant="outline" onClick={() => setSearchValue('')} size="inline" colorScheme="black">
							<Search16 />
						</Button>
					</Tooltip>
					{rowData && (
						<Typography variant={'DesktopH7Medium'} color={'gray.1000'} textAlign="start">
							{rowData.length} Data {pluralize('sources', rowData.length)}
						</Typography>
					)}
				</>
			)}
		</Flex>
	);

	return (
		<Box>
			{isLoadingConnectorsList ? (
				<SkeletonTableLayout />
			) : (
				connectorsList.length > 0 && (
					<>
						<SearchBar />
						<Box paddingBottom={'32px'}>
							{columnDefs && rowData && (
								<GridTableBody
									data={rowData}
									maxRowsToShow={MAX_ROWS_NUMBER}
									headerHeight={49}
									rowHeight={80}
									columnDefs={columnDefs}
								/>
							)}
						</Box>
					</>
				)
			)}
		</Box>
	);
};
