import { TransformedObject, UpdatedFieldsType } from './types';

export const testConnectorsValue = (name: string) => /^[a-z_][a-z0-9_]*$/.test(name);

export const parseArrayIntoObject = (array: UpdatedFieldsType[]): TransformedObject => {
	return array.reduce((acc: Record<string, string>, { id, value }) => {
		acc[id] = value;
		return acc;
	}, {});
};
