import { PrimitiveAtom } from 'jotai';
import { Metric, FormulaMetric } from '../../../lib/completions/semanticTypes/metrics.schema';

export const CREATE_NEW_METRIC_INITIAL_PROMPT_QUERY_PARAM = 'initialPrompt';

export type DiscoverMetricsSuggested = {
	metric: string;
	display: string;
	period?: string;
	breakdown?: string;
	breakdownName?: string;
	periodDescription?: string;
};
export type DiscoverMetricsResult = { reasoning: string; suggestions: DiscoverMetricsSuggested[]; examples: string[] };

export type AskAIChatVote = 'up' | 'down';

export type AskAIChatMessage = {
	userPrompt: string;
	messageKey: string;
	error?: boolean;
	result?: DiscoverMetricsResult;
	completionTime?: number;
	vote?: AskAIChatVote;
};

export type AskAIChatCardProps = {
	chatMessageAtom: PrimitiveAtom<AskAIChatMessage>;
	chatIndex: number;
};

// Agents
export type AIAgentTriggerType = 'explicit' | 'silent';

export type AIAgentName = 'aggregate-metric-builder' | 'formula-metric-builder';

export type CreateAIAgentThreadResponse = {
	agentName: string;
	threadId: string;
};

export type AIAgentMetricBuilderOp = {
	create?: { prompt: string };
	edit?: { definition: string; changes: { field: string; value: string }[] };
};

export type AIAgentMetricBuilderResponse = {
	status: 'error' | 'complete';
	suggestion?: { metric?: Metric; formula_metric?: FormulaMetric };
	error?: string;
};
