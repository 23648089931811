import { gql } from '@apollo/client';

export const UserRequestSetupConnector = gql`
	query UserRequestSetupConnector($connectorName: String!) {
		userRequestSetupConnector(connectorName: $connectorName) {
			status
		}
	}
`;

export const ConfigNewSource = gql`
	mutation ConfigNewSource(
		$dataIntegrator: String!
		$metadata: JSON
		$source: String!
		$destination: String = "postgres_rds_warehouse"
	) {
		configNewSource(dataIntegrator: $dataIntegrator, source: $source, destination: $destination, metadata: $metadata) {
			status
			tenantId
			dataIntegrator
			integratorMetadata
		}
	}
`;

export const ReauthenticateSource = gql`
	mutation ReauthenticateSource($sourceId: String!, $hideSetupGuide: Boolean!) {
		reauthenticateSource(dataIntegrator: "fivetran", sourceId: $sourceId, hideSetupGuide: $hideSetupGuide) {
			status
			integratorMetadata
		}
	}
`;
