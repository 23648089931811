import { useCallback, useEffect, useMemo, useRef } from 'react';
import Box from '@components/Box';
import Flex from '@components/Flex';
import { AISparksBlack16, ArrowDownMedium16, ArrowUpMedium16, Copy16, OkSmall16 } from '../Icons';
import Typography from '@components/Typography';
import Button from '@components/Button';
import Popover from '@components/Popover';
import { useAskAIBusyState } from './hooks/useAskAIQueryRunState';
import { PendingAISuggestion, useAIPendingSuggestions } from './hooks/useAIPendingSuggestions';
import classes from './AskAI.module.scss';
import { useMainPageContainerRef } from '../../../layout/PageV2';
import Tooltip from '../Tooltip';
import useToast from '../../hooks/ui/useToast';
import Divider from '../Divider';
import { useReportAIEvent } from './hooks/useReportAIEvent';
import Markdown from '../Markdown';

function SuggestionButton({
	onClick,
	icon,
	text,
	tooltip,
	isMain,
}: {
	onClick: VoidFunction;
	icon?: React.ReactElement;
	text?: string;
	tooltip?: string;
	isMain?: boolean;
}) {
	return (
		<Tooltip size={'md'} label={tooltip}>
			<Button
				isIconOnly={!text}
				variant={isMain ? 'solid' : 'outline'}
				colorScheme={isMain ? 'blue' : text ? 'black' : 'lighterGray'}
				size={'xs'}
				onClick={onClick}
				borderRadius={'6px'}
			>
				<Flex gap={'4px'} alignItems={'center'}>
					{icon} {text}
				</Flex>
			</Button>
		</Tooltip>
	);
}

type AskAIFieldSuggestionBodyProps = {
	totalPending: number;
	fieldIndex: number;
	suggestedValues?: string[];
	existingValues?: string[];
	suggestedValueDisplay?: React.ReactNode;
	onSuggestionAccepted?: (values?: string[]) => void;
	onSuggestionRejected?: (values?: string[]) => void;
	onActivateNextSuggestion?: () => void;
	onActivatePrevSuggestion?: () => void;
	borderColor?: string;
	width?: string;
	reportMetadata: Record<string, any>;
};

function AskAIFieldSuggestionBody({
	totalPending,
	fieldIndex,
	suggestedValues,
	suggestedValueDisplay,
	onSuggestionAccepted,
	onSuggestionRejected,
	onActivateNextSuggestion,
	onActivatePrevSuggestion,
	borderColor,
	width,
	reportMetadata,
}: AskAIFieldSuggestionBodyProps) {
	const toast = useToast();
	const { reportAICopilotEvent } = useReportAIEvent();

	const onAccept = useCallback(() => {
		onSuggestionAccepted?.(suggestedValues);
		reportAICopilotEvent({ event: 'ai-copilot-accept-suggestion', metaData: reportMetadata });
	}, [onSuggestionAccepted, reportAICopilotEvent, reportMetadata, suggestedValues]);

	const onDismiss = useCallback(() => {
		onSuggestionRejected?.(suggestedValues);
		reportAICopilotEvent({ event: 'ai-copilot-dismiss-suggestion', metaData: reportMetadata });
	}, [onSuggestionRejected, reportAICopilotEvent, suggestedValues, reportMetadata]);

	const onNextSuggestion = useCallback(() => {
		onActivateNextSuggestion?.();
		reportAICopilotEvent({ event: 'ai-copilot-next-suggestion', metaData: reportMetadata });
	}, [onActivateNextSuggestion, reportAICopilotEvent, reportMetadata]);

	const onPrevSuggestion = useCallback(() => {
		onActivatePrevSuggestion?.();
		reportAICopilotEvent({ event: 'ai-copilot-previous-suggestion', metaData: reportMetadata });
	}, [onActivatePrevSuggestion, reportAICopilotEvent, reportMetadata]);

	const onCopyToClipboard = useCallback(() => {
		if (suggestedValues) {
			navigator.clipboard
				.writeText(suggestedValues?.join(' and '))
				.then(() => toast({ variant: 'ok', message: 'Copied to clipboard' }));
		}
		reportAICopilotEvent({
			event: 'ai-copilot-clipboard-copy-suggestion',
			metaData: reportMetadata,
		});
	}, [toast, reportAICopilotEvent, suggestedValues, reportMetadata]);

	return (
		<Flex
			direction={'column'}
			width={width}
			padding={'11px 15px 15px 15px '}
			gap={'12px'}
			border={'1px solid'}
			borderColor={borderColor}
			borderRadius={'8px'}
			backgroundColor={'white'}
		>
			<Flex flex={1} gap={'8px'} alignItems={'center'}>
				<Typography variant={'Paragraph14R'} color={'gray.1000'} wordBreak={'break-word'}>
					{suggestedValueDisplay ? suggestedValueDisplay : <Markdown>{suggestedValues?.join(' **and** ')}</Markdown>}
				</Typography>
			</Flex>
			<Flex flex={1} alignItems={'center'} justifyContent={'space-between'}>
				<Flex alignItems={'center'} gap={'8px'} color={'gray.600'}>
					<SuggestionButton onClick={onAccept} icon={<OkSmall16 />} text={'Accept'} isMain={true} />
					<SuggestionButton onClick={onDismiss} text={'Dismiss'} isMain={false} />
				</Flex>
				<Flex alignItems={'center'} gap={'8px'} color={'gray.600'}>
					<Typography variant={'DesktopH8Regular'}>{`${fieldIndex + 1} of ${totalPending}`}</Typography>
					<Flex alignItems={'center'}>
						<SuggestionButton onClick={onNextSuggestion} icon={<ArrowDownMedium16 />} tooltip={'Next'} />
						<SuggestionButton onClick={onPrevSuggestion} icon={<ArrowUpMedium16 />} tooltip={'Previous'} />
						<Box height={'24px'} margin={'0 8px'}>
							<Divider direction={'vertical'} />
						</Box>
						<SuggestionButton onClick={onCopyToClipboard} icon={<Copy16 />} tooltip={'Copy to clipboard'} />
					</Flex>
				</Flex>
			</Flex>
		</Flex>
	);
}

function isSuggestedValueDifferentFromExisting(suggestedValueArray?: string[], existingValueArray?: string[]) {
	return (
		suggestedValueArray?.join('|').toLocaleLowerCase().trim() !==
		existingValueArray?.join('|').toLocaleLowerCase().trim()
	);
}

function MainAISuggestionButton({
	suggestion,
	isBusy,
	controlledFieldRef,
	popoverContainerRef,
	onSuggestionAccepted,
	reportMetadata,
}: {
	suggestion: PendingAISuggestion;
	isBusy: boolean;
	controlledFieldRef: React.RefObject<HTMLDivElement>;
	popoverContainerRef?: React.RefObject<HTMLDivElement>;
} & Pick<
	AskAIFieldSuggestionBodyProps,
	'suggestedValues' | 'suggestedValueDisplay' | 'onSuggestionAccepted' | 'onSuggestionRejected' | 'reportMetadata'
>) {
	const { reportAICopilotEvent } = useReportAIEvent();
	const {
		pendingFields,
		totalPending,
		activeField,
		getFieldIndex,
		removePendingSuggestion,
		addPendingSuggestion,
		setActiveSuggestion,
		activateNextPendingSuggestion,
		activatePrevPendingSuggestion,
	} = useAIPendingSuggestions();

	const { field, suggestedValue, existingValue, suggestedValueDisplay } = suggestion;

	const suggestedValuesArray = useMemo(
		() => (suggestedValue === undefined || Array.isArray(suggestedValue) ? suggestedValue : [suggestedValue]),
		[suggestedValue]
	);
	const exsitingValuesArray = useMemo(
		() => (existingValue === undefined || Array.isArray(existingValue) ? existingValue : [existingValue]),
		[existingValue]
	);

	const isActive = useMemo(() => field === activeField, [activeField, field]);
	const fieldIndex = useMemo(() => getFieldIndex(field), [field, getFieldIndex]);
	const reportEventMetadata = useMemo(
		() => ({ ...reportMetadata, field, suggestedValue, existingValue }),
		[existingValue, field, reportMetadata, suggestedValue]
	);

	const isValidSuggestion = useMemo(() => {
		const hasSuggestedValue = (suggestedValuesArray ?? []).length > 0;
		const suggestedValueIsDifferent = isSuggestedValueDifferentFromExisting(suggestedValuesArray, exsitingValuesArray);
		return hasSuggestedValue && suggestedValueIsDifferent;
	}, [exsitingValuesArray, suggestedValuesArray]);

	const onActivateNextSuggestion = useCallback(() => {
		activateNextPendingSuggestion();
	}, [activateNextPendingSuggestion]);

	const onActivatePrevSuggestion = useCallback(() => {
		activatePrevPendingSuggestion();
	}, [activatePrevPendingSuggestion]);

	const onSuggestionClosed = useCallback(() => {
		setActiveSuggestion(field, false);
	}, [setActiveSuggestion, field]);

	const onToggleActive = useCallback(() => {
		setActiveSuggestion(field, !isActive);
		reportAICopilotEvent({
			event: 'ai-copilot-click-suggestion',
			metaData: reportEventMetadata,
		});
	}, [field, isActive, reportAICopilotEvent, reportEventMetadata, setActiveSuggestion]);

	const onDismissed = useCallback(() => {
		onActivateNextSuggestion();
		removePendingSuggestion(field);
	}, [field, onActivateNextSuggestion, removePendingSuggestion]);

	const onAccepted = useCallback(
		(values?: string[]) => {
			onActivateNextSuggestion();
			removePendingSuggestion(field);
			onSuggestionAccepted?.(values);
		},
		[onActivateNextSuggestion, removePendingSuggestion, field, onSuggestionAccepted]
	);

	const shouldBeDisplayed = useMemo(
		() => (pendingFields.has(field) && isValidSuggestion) || isBusy,
		[isBusy, isValidSuggestion, pendingFields, field]
	);

	useEffect(() => {
		if (isValidSuggestion) {
			addPendingSuggestion(field);
		} else {
			removePendingSuggestion(field);
		}
	}, [addPendingSuggestion, removePendingSuggestion, field, isValidSuggestion]);

	useEffect(() => {
		if (isActive && shouldBeDisplayed) {
			// due to a long standing bug in chromium (https://issues.chromium.org/issues/40572042) we can't use smooth scrolling.
			// fix for chrome bug is in the works: https://issues.chromium.org/issues/325081538
			controlledFieldRef.current?.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
		}
	}, [controlledFieldRef, isActive, shouldBeDisplayed]);

	if (!shouldBeDisplayed) return null;

	return (
		<Flex position={'absolute'} top={'-35px'} right={'0'} padding={'4px 0'} borderRadius={'6px'}>
			<Popover
				isOpen={isValidSuggestion && isActive}
				onClose={onSuggestionClosed}
				placement={'right-start'}
				gutter={8}
				popoverContentProps={{ borderRadius: '8px' }}
				portalContainerRef={popoverContainerRef}
				autoFocus={true}
				triggerElement={
					<Box
						className={`${classes['ai-suggestion-indicator']} ${isActive ? classes['ai-suggestion-active'] : ''} ${
							isBusy ? classes['ai-suggestion-busy'] : ''
						}`}
						onClick={isValidSuggestion ? onToggleActive : undefined}
						cursor={isValidSuggestion ? 'pointer' : undefined}
					>
						<AISparksBlack16 />
					</Box>
				}
			>
				<AskAIFieldSuggestionBody
					borderColor={'gray.300'}
					width={'380px'}
					totalPending={totalPending}
					fieldIndex={fieldIndex}
					suggestedValues={suggestedValuesArray}
					suggestedValueDisplay={suggestedValueDisplay}
					onSuggestionAccepted={onAccepted}
					onSuggestionRejected={onDismissed}
					onActivateNextSuggestion={onActivateNextSuggestion}
					onActivatePrevSuggestion={onActivatePrevSuggestion}
					reportMetadata={reportEventMetadata}
				/>
			</Popover>
		</Flex>
	);
}

export type AskAISuggestorProps = {
	suggestion: PendingAISuggestion;
	children: React.ReactNode;
	enabled?: boolean;
} & Pick<AskAIFieldSuggestionBodyProps, 'onSuggestionAccepted' | 'onSuggestionRejected' | 'reportMetadata'>;

export function AskAIFieldSuggestor({
	suggestion,
	children,
	enabled = true,
	onSuggestionAccepted,
	onSuggestionRejected,
	reportMetadata,
}: AskAISuggestorProps) {
	const [isBusy] = useAskAIBusyState();

	// this is used to control the auto scroll-into-view of the open suggestion
	const ref = useRef<HTMLDivElement>(null);

	// this is used to place the suggestion popover in the dom tree under the
	// main page element to make sure the suggestion is always rendered beneath the top navigation bar
	const popoverContainerRef = useMainPageContainerRef();

	return (
		<Box width={'100%'} position={'relative'} ref={ref}>
			{enabled && (
				<MainAISuggestionButton
					suggestion={suggestion}
					isBusy={isBusy}
					onSuggestionAccepted={onSuggestionAccepted}
					onSuggestionRejected={onSuggestionRejected}
					controlledFieldRef={ref}
					popoverContainerRef={popoverContainerRef}
					reportMetadata={reportMetadata}
				/>
			)}
			{children}
		</Box>
	);
}
