import { useDisclosure } from '@chakra-ui/react';
import Flex from '@components/Flex';
import Box from '@components/Box';
import { ICellRendererParams } from 'ag-grid-community';
import Button from 'src/common/components/Button';
import Popover from 'src/common/components/Popover';
import { DotsSolid16, Loading16, Salesforce20, TreeStructure15 } from 'src/common/components/Icons';
import ListItem from 'src/common/components/ListItem';
import useNavigation from 'src/services/useNavigation';

export function ActionsRenderer() {
	function Cell(params: ICellRendererParams) {
		const cellValue = params?.value || {};
		const { isOpen, onClose, onOpen } = useDisclosure();
		const { navigate } = useNavigation();

		const onEdit = () => navigate({ path: cellValue.editLink });
		const onSync = () => 0;

		return (
			<Flex minWidth={'80px'} justifyContent={'center'} alignItems={'center'} gap={'4px'}>
				<Popover
					popoverContentProps={{
						borderRadius: '10px',
					}}
					isOpen={isOpen}
					onClose={onClose}
					placement="bottom-end"
					triggerElement={(isOpen) => (
						<Button
							onClick={(e) => {
								e.stopPropagation();
								if (isOpen) onClose();
								else onOpen();
							}}
							mixBlendMode={'multiply'}
							size={'inline'}
							variant="outline"
							colorScheme="stone"
							isIconOnly
							isActive={isOpen}
						>
							<DotsSolid16 />
						</Button>
					)}
				>
					<Box borderRadius={'10px'} border={'1px solid'} borderColor={'gray.300'} paddingY="8px" width={'260px'}>
						<ListItem color="gray.1100" size={'sm'} label={'Sync now'} prefixIcon={<Loading16 />} onClick={onSync} />
						<Box marginY="8px" marginX={'12px'} backgroundColor={'gray.300'} width={'100%'} height={'1px'} />
						<ListItem
							color="gray.1100"
							size={'sm'}
							label={`Edit ${cellValue.sourceName}...`}
							prefixIcon={<Salesforce20 />}
							onClick={onEdit}
						/>
					</Box>
				</Popover>
				{cellValue.shareLink && (
					<Button
						mixBlendMode={'multiply'}
						onClick={() => navigate({ path: cellValue.shareLink })}
						size={'inline'}
						variant="outline"
						colorScheme="stone"
						isIconOnly
					>
						<TreeStructure15 />
					</Button>
				)}
			</Flex>
		);
	}
	return Cell;
}
