import { DropdownStringEditor } from '../../Editors/DropdownStringEditor';
import { ValueEditorProps } from './ValueEditorByFilter';

export function FilterStringDropdownEditor({
	options,
	displayValue,
	values,
	onApply,
	prefixComponent,
	isEditorAutoOpened = false,
	eventMetadata,
	isEditing,
	onOpenEditor,
	onCloseEditor,
	isLoadingDimensionValues,
}: ValueEditorProps) {
	return (
		<DropdownStringEditor
			options={options}
			displayValue={displayValue}
			values={values.map((v) => v?.toString() ?? null)}
			onApply={onApply}
			applyButtonLabel={'Add'}
			prefixComponent={prefixComponent}
			defaultIsEditing={isEditorAutoOpened}
			tooltip={'Edit values'}
			isLoadingOptions={isLoadingDimensionValues}
			placeholder={'Search for values'}
			isSelectable
			eventName={'filters-apply-filter'}
			eventMetadata={eventMetadata}
			searchValuesEventMetadata={{ event: 'filters-search-values', metaData: eventMetadata }}
			selectAllEventMetadata={{ event: 'filters-select-all-values-toggle', metaData: eventMetadata }}
			selectAllMatchingEventMetadata={{ event: 'filters-select-matching-values-toggle', metaData: eventMetadata }}
			isEditing={isEditing}
			onOpenEditor={onOpenEditor}
			onCloseEditor={onCloseEditor}
		/>
	);
}
