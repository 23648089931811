import Page from 'src/layout/Page';
import Box from '@components/Box';
import Flex from '@components/Flex';
import useFeatureFlag from 'src/common/hooks/stores/useFeatureFlag';
import { ConnectorsBanner } from './components/ConnectorsBanner';
import { ConnectorsTable } from './components/ConnectorsTable';
import { useModal } from 'src/common/hooks/ui/useModal';
import { useEffect } from 'react';
import { DataConnectorsThankYouModal } from 'src/common/components/TopNavigation/DataConnectorsModal/DataConnectorsThankYouModal';
import useNavigation from 'src/services/useNavigation';
import { ConnectorsPagePath } from '.';

type ConnectorsPageProps = {
	isThankYouModalActive?: boolean;
};

export default function ConnectorsPage({ isThankYouModalActive = false }: ConnectorsPageProps) {
	const isSightfull2 = useFeatureFlag('pulse.sightfull2.enable');
	const isDataConnectorsPageEnabled = useFeatureFlag('pulse.sightfull2.dataConnectorsPage.enable');

	if (!isSightfull2 || !isDataConnectorsPageEnabled) return null;

	return (
		<Page page="Connectors Page">
			<ConnectorsPageGuard isThankYouModalActive={isThankYouModalActive} />
		</Page>
	);
}

export function ConnectorsPageGuard({ isThankYouModalActive = false }: ConnectorsPageProps) {
	const { isOpen, onOpen, onClose } = useModal();
	const { navigate } = useNavigation();

	useEffect(() => {
		if (isThankYouModalActive) onOpen();
	}, [isThankYouModalActive, onOpen]);

	return (
		<Flex direction={'column'} height="100%" padding={'32px'}>
			<Box marginBottom={'36px'}>
				<ConnectorsBanner />
			</Box>
			<Box>
				<ConnectorsTable />
			</Box>
			<DataConnectorsThankYouModal
				isOpen={isOpen}
				onClose={() => {
					onClose();
					navigate({ path: ConnectorsPagePath });
				}}
			/>
		</Flex>
	);
}
