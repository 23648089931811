import Box from '@components/Box';
import Flex from '@components/Flex';
import Image from '@components/Image';
import { useMemo } from 'react';
import { AdvancedSelect } from 'src/common/components/AdvancedSelect';
import { GoogleSheetsTransparent16 } from 'src/common/components/Icons';
import Spinner from 'src/common/components/Spinner';
import { useSchemasDataQuery } from 'src/generated/graphql';
import { SelectLabel } from 'src/pages/MetricPage/components/CalculatePanel/Builder/components';
import DescriptionField from 'src/pages/OntologyPage/components/DescriptionField';
import { NormalizedOntology } from 'src/pages/OntologyPage/hooks/useOntologyPageState';
import { EntityDefinitionState, KeysType } from 'src/pages/OntologyPage/hooks/useOntologyUpsertEntity';
import { parseValue } from 'src/pages/OntologyPage/utils/utils';
import { useReportEvent } from 'src/services/analytics';
import { usePermissionCheck } from 'src/stores/environment';
import { Permissions } from 'src/types/environment';
import { Filters, PrimaryKeys } from '../GroupTypes';
import { removeUnderscoresAndCapitalize } from 'src/normalize';
import { connectorsInfos } from 'src/common/components/TopNavigation/DataConnectorsModal/connectors';
import PLACEHOLDER_ICON from 'src/assets/icons/database-16.svg';
import { toLower } from 'lodash';

interface ModalFormProps {
	isEditEntity: boolean;
	selectedOntology?: NormalizedOntology | null;
	entityState: EntityDefinitionState | null;
	onChangeValue: (keys: KeysType) => void;
}

export function ModalForm({ isEditEntity = false, selectedOntology, entityState, onChangeValue }: ModalFormProps) {
	const hasWritePermission = usePermissionCheck().isHavingPermission(Permissions.writeEntities);
	const { data_source, primary_keys, meta } = entityState || {};
	const { schema, table, filters } = data_source || {};
	const { description } = meta || {};

	const { data: schemas, loading } = useSchemasDataQuery();

	const { reportEvent } = useReportEvent({ feature: 'Upsert entity modal' });

	const schemaOptions = useMemo(
		() =>
			schemas?.schemasData.map((schema) => {
				const sourceInfo = connectorsInfos.find((connector) => connector.id === toLower(schema.meta.sourceName));
				return {
					value: schema.name,
					label: removeUnderscoresAndCapitalize(schema.name),
					icon: <Image src={sourceInfo?.icon || PLACEHOLDER_ICON} maxWidth={'16px'} height={'16px'} width={'100%'} />,
				};
			}),
		[schemas?.schemasData]
	);

	const sourceControlledValue = useMemo(
		() =>
			schema
				? schemaOptions?.find((el) => el.value === schema) || { label: schema || '', value: schema || '', icon: null }
				: undefined,
		[schema, schemaOptions]
	);

	const sourceTableOptions = useMemo(
		() =>
			schemas?.schemasData
				?.find((el) => el?.name === schema)
				?.tables.map((el) => ({
					value: el.name,
					label: el.name,
					icon: <GoogleSheetsTransparent16 />,
				})),

		[schemas?.schemasData, schema]
	);

	const sourceTableControlledValue = useMemo(
		() =>
			table
				? sourceTableOptions?.find((el) => el.value === table) || {
						label: table || '',
						value: table || '',
						icon: null,
				  }
				: undefined,
		[table, sourceTableOptions]
	);

	const parsedDataSource = useMemo(() => parseValue(data_source) || [], [data_source]);
	const parseMeta = useMemo(() => parseValue(meta) || [], [meta]);
	const primaryKeysSelected = useMemo(() => primary_keys?.filter((el) => !!el)?.length, [primary_keys]);
	const primaryKeysOptions = useMemo(
		() => schemas?.schemasData?.find((el) => el?.name === schema)?.tables?.find((el) => el?.name === table),
		[schema, schemas?.schemasData, table]
	);

	return loading ? (
		<Flex width={'100%'} height={'416px'} justifyContent={'center'} alignItems={'center'} flexDirection={'column'}>
			<Spinner thickness="3px" color="blue.600" size={'60px'} />
		</Flex>
	) : (
		<>
			<Box marginBottom={'32px'}>
				<SelectLabel marginBottom={'8px'} color={'gray.1100'} text="Schema" />
				<AdvancedSelect
					entityName={selectedOntology?.name}
					isDisabled={!hasWritePermission}
					label="Schema"
					isWarningModalEnabled={!!(table || primaryKeysSelected)}
					onReportEvent={() =>
						reportEvent({
							event: 'ontology-object-edit-UI-input-provided',
							metaData: {
								objectType: 'entity',
								objectName: isEditEntity ? selectedOntology?.name : '',
								fieldname: 'source',
								input: sourceControlledValue?.label,
								flow: isEditEntity ? 'edit' : 'create',
							},
						})
					}
					onChange={(value) =>
						onChangeValue([
							{
								key: 'data_source',
								value: [
									{ key: 'schema', value: value.value },
									{ key: 'table', value: '' },
								],
							},
							{ key: 'primary_keys', value: [''] },
						])
					}
					placeholder="Select"
					options={schemaOptions}
					controlledValue={sourceControlledValue}
					dataIntercomTarget="Schema"
				/>
			</Box>
			<Box marginBottom={'32px'}>
				<SelectLabel marginBottom={'8px'} color={'gray.1100'} text="Source table" />
				<AdvancedSelect
					entityName={selectedOntology?.name}
					onReportEvent={() =>
						reportEvent({
							event: 'ontology-object-edit-UI-input-provided',
							metaData: {
								objectType: 'entity',
								objectName: isEditEntity ? selectedOntology?.name : '',
								fieldname: 'table',
								input: sourceTableControlledValue?.value,
								flow: isEditEntity ? 'edit' : 'create',
							},
						})
					}
					isDisabled={!schema || !hasWritePermission}
					onChange={(value) =>
						onChangeValue([
							{
								key: 'data_source',
								value: [...parsedDataSource, { key: 'table', value: value.value }],
							},
							{ key: 'primary_keys', value: [''] },
						])
					}
					placeholder="Select"
					options={sourceTableOptions}
					controlledValue={sourceTableControlledValue}
					dataIntercomTarget="source table"
				/>
			</Box>
			<Box marginBottom={'32px'}>
				<PrimaryKeys
					onChangeValue={onChangeValue}
					isDisabled={!table || !hasWritePermission}
					keysOptions={primaryKeysOptions?.columns}
					selectedOntology={selectedOntology}
					isEditEntity={isEditEntity}
					primary_keys={primary_keys}
				/>
			</Box>
			<Box marginBottom={'32px'}>
				<Filters
					primaryKeysOptions={primaryKeysOptions?.columns}
					selectedOntology={selectedOntology}
					isEditEntity={isEditEntity}
					filters={filters}
					isDisabled={!table || !hasWritePermission}
					onChangeValue={onChangeValue}
					parsedDataSource={parsedDataSource}
					sourceSystem={schemaOptions?.find((el) => el.value === schema)?.label}
				/>
			</Box>
			<Box>
				<DescriptionField
					placeholder={'Describe entity'}
					isDisabled={!hasWritePermission}
					value={description || ''}
					onBlur={() =>
						reportEvent({
							event: 'ontology-object-edit-UI-input-provided',
							metaData: {
								objectType: 'entity',
								objectName: isEditEntity ? selectedOntology?.name : '',
								fieldname: 'description',
								input: description,
								flow: isEditEntity ? 'edit' : 'create',
							},
						})
					}
					onChange={(value) =>
						onChangeValue([
							{
								key: 'meta',
								value: [...parseMeta, { key: 'description', value }],
							},
						])
					}
				/>
			</Box>
		</>
	);
}
