import useRESTAuthInfo from '@hooks/useRESTAuthInfo';
import { useAskAIBusyState } from './useAskAIQueryRunState';
import { useCallback } from 'react';
import { AIAgentName, CreateAIAgentThreadResponse } from '../types';
import { atom, useAtom, Provider } from 'jotai';
import { useAtomCallback } from 'jotai/utils';
import useToast from '../../../hooks/ui/useToast';

type ThreadID = string;
type CallID = string;
type AIAgentsContext = {
	agentName?: string;
	threadId?: string;
	latestCallIds: Map<ThreadID, CallID>;
	latestResult?: any;
	latestError?: string;
};

const aiAgentsContextScope = Symbol();
const AIAgentsContextAtom = atom<AIAgentsContext>({ latestCallIds: new Map() });

export function AIAgentsContextBoundary({ children }: { children: React.ReactNode }) {
	return (
		<Provider initialValues={[[AIAgentsContextAtom, { latestCallIds: new Map() }]]} scope={aiAgentsContextScope}>
			{children}
		</Provider>
	);
}

export function useAIAgents() {
	const toast = useToast();
	const { accessToken, tenantId, role, apiUrl } = useRESTAuthInfo();
	const [isBusy, setAskAIBusyState] = useAskAIBusyState();
	const [aiAgentsContext, setAIAgentsContext] = useAtom(AIAgentsContextAtom, aiAgentsContextScope);

	const getLatestCallId = useAtomCallback(
		useCallback((get, set, threadId?: ThreadID) => {
			if (!threadId) return;
			return get(AIAgentsContextAtom).latestCallIds.get(threadId);
		}, []),
		aiAgentsContextScope
	);

	const handleAIAgentError = useCallback(
		(error: string) => {
			toast({ variant: 'error', message: error, duration: 5000 });
		},
		[toast]
	);

	const callAIAgent = useCallback(
		async <T,>({
			agentName,
			threadId,
			body,
		}: {
			agentName: AIAgentName;
			threadId?: string;
			body?: any;
		}): Promise<T | undefined> => {
			try {
				let url = `${apiUrl}/ai/agents/${agentName}/threads`;
				if (threadId) {
					url = `${url}/${threadId}`;
				}
				const callId: CallID = `${Date.now()}-${Math.random()}`;
				if (threadId) {
					aiAgentsContext.latestCallIds.set(threadId, callId);
				}
				setAskAIBusyState(true);
				const response = await fetch(url, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${accessToken}`,
						'X-Role': role,
						'X-Tenant-Id': `${tenantId}`,
					},
					body: typeof body === 'object' ? JSON.stringify(body) : body,
				});

				// we are only interested in the latest agent call result in any specific thread,
				// so return undefined in case this isn't the latest agent call on the thread.
				// can happen if another agent call went out before this agent call finished.
				if (threadId && getLatestCallId(threadId) !== callId) {
					return;
				}

				if (response.status >= 400) {
					throw new Error(`Something went wrong (${response.statusText.toLowerCase()})`);
				}
				const data = await response.json();
				setAIAgentsContext((cur) => ({ ...cur, latestError: data.error, latestResult: data }));
				return data;
			} finally {
				setAskAIBusyState(false);
			}
		},
		[
			accessToken,
			aiAgentsContext.latestCallIds,
			apiUrl,
			getLatestCallId,
			role,
			setAIAgentsContext,
			setAskAIBusyState,
			tenantId,
		]
	);

	const createAIAgentThread = useCallback(
		async ({ agentName }: { agentName: AIAgentName }) => {
			const data = await callAIAgent<CreateAIAgentThreadResponse>({ agentName });
			if (data) {
				setAIAgentsContext((cur) => ({ ...cur, threadId: data.threadId, agentName: data.agentName }));
			}
			return data;
		},
		[callAIAgent, setAIAgentsContext]
	);

	const getOrCreateAIAgentThread = useCallback(
		async ({ agentName }: { agentName: AIAgentName }) => {
			if (aiAgentsContext.threadId) {
				return aiAgentsContext.threadId;
			}
			const response = await createAIAgentThread({ agentName });
			return response?.threadId;
		},
		[aiAgentsContext.threadId, createAIAgentThread]
	);

	const clearAIAgentsContext = useCallback(
		() => setAIAgentsContext({ latestCallIds: new Map() }),
		[setAIAgentsContext]
	);

	return {
		aiAgentsContext,
		isBusy,
		createAIAgentThread,
		getOrCreateAIAgentThread,
		callAIAgent,
		handleAIAgentError,
		clearAIAgentsContext,
	};
}
