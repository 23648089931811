import { SupportedDimensionTypes, SupportedOperators } from 'src/generated/graphql';
import { DimensionType } from 'src/lib/completions/semanticTypes/normalization.schema';
import { FilterV2Value } from 'src/pages/MetricPage/utils/state.types';

export const FILTER_NULL_OPTION_NAME = '(NULL)';
export const FILTER_EMPTY_STRING_OPTION_NAME = '(empty string)';
export const FILTER_NULL_OPTION = {
	title: FILTER_NULL_OPTION_NAME,
	value: null,
};
export const FILTER_EMPTY_STRING_OPTION = { title: FILTER_EMPTY_STRING_OPTION_NAME, value: '' };

export const dimensionTypeToFilterType: { [t in DimensionType]: SupportedDimensionTypes } = {
	bool: SupportedDimensionTypes.Bool,
	string: SupportedDimensionTypes.String,
	number: SupportedDimensionTypes.Number,
	boolean: SupportedDimensionTypes.Bool,
	date: SupportedDimensionTypes.String,
	timestamp: SupportedDimensionTypes.String, // TODO: change these to be date, when actually supporting dates
	timestampz: SupportedDimensionTypes.String,
	timestamptz: SupportedDimensionTypes.String,
};

export const defaultOperatorByFilterType: { [t in SupportedDimensionTypes]: SupportedOperators } = {
	[SupportedDimensionTypes.Bool]: SupportedOperators.OneOf,
	[SupportedDimensionTypes.Number]: SupportedOperators.OneOf,
	[SupportedDimensionTypes.String]: SupportedOperators.OneOf,
	[SupportedDimensionTypes.Date]: SupportedOperators.IsNotNull,
};

export const supportedOperatorsByFilterType: { [t in SupportedDimensionTypes]: SupportedOperators[] } = {
	[SupportedDimensionTypes.Bool]: [SupportedOperators.OneOf],
	[SupportedDimensionTypes.Number]: [
		SupportedOperators.OneOf,
		SupportedOperators.NotOneOf,
		SupportedOperators.GreaterThan,
		SupportedOperators.GreaterThanOrEqualTo,
		SupportedOperators.LessThan,
		SupportedOperators.LessThanOrEqualTo,
		SupportedOperators.Between,
		SupportedOperators.NotBetween,
		SupportedOperators.IsNotNull,
		SupportedOperators.IsNull,
	],
	[SupportedDimensionTypes.String]: [
		SupportedOperators.OneOf,
		SupportedOperators.NotOneOf,
		SupportedOperators.Contains,
		SupportedOperators.NotContains,
		SupportedOperators.IsNotNull,
		SupportedOperators.IsNull,
	],
	[SupportedDimensionTypes.Date]: [SupportedOperators.IsNotNull],
};

export const typeMappingByFilterType: {
	[t in SupportedDimensionTypes]: (v: FilterV2Value) => FilterV2Value;
} = {
	[SupportedDimensionTypes.Bool]: (v) => (v == null ? null : v === true || v == 'true'),
	[SupportedDimensionTypes.Number]: (v) => Number(v),
	[SupportedDimensionTypes.String]: (v) => (v == null ? v : String(v)),
	[SupportedDimensionTypes.Date]: (v) => String(v),
};

export const supportedOperatorDisplayNames: {
	[t in SupportedOperators]: { [t in SupportedDimensionTypes]: string };
} = {
	[SupportedOperators.OneOf]: {
		[SupportedDimensionTypes.Bool]: 'Is',
		[SupportedDimensionTypes.Number]: 'Equals',
		[SupportedDimensionTypes.String]: 'Is',
		[SupportedDimensionTypes.Date]: 'Is',
	},
	[SupportedOperators.OneOfSensitive]: {
		[SupportedDimensionTypes.Bool]: 'Is (case sensitive)',
		[SupportedDimensionTypes.Number]: 'Is (case sensitive)',
		[SupportedDimensionTypes.String]: 'Is (case sensitive)',
		[SupportedDimensionTypes.Date]: 'Is (case sensitive)',
	},
	[SupportedOperators.Contains]: {
		[SupportedDimensionTypes.Bool]: 'Contains',
		[SupportedDimensionTypes.Number]: 'Contains',
		[SupportedDimensionTypes.String]: 'Contains',
		[SupportedDimensionTypes.Date]: 'Contains',
	},
	[SupportedOperators.ContainsSensitive]: {
		[SupportedDimensionTypes.Bool]: 'Contains (case sensitive)',
		[SupportedDimensionTypes.Number]: 'Contains (case sensitive)',
		[SupportedDimensionTypes.String]: 'Contains (case sensitive)',
		[SupportedDimensionTypes.Date]: 'Contains (case sensitive)',
	},
	[SupportedOperators.NotContains]: {
		[SupportedDimensionTypes.Bool]: 'Not contains',
		[SupportedDimensionTypes.Number]: 'Not contains',
		[SupportedDimensionTypes.String]: 'Does not contain',
		[SupportedDimensionTypes.Date]: 'Not contains',
	},
	[SupportedOperators.NotContainsSensitive]: {
		[SupportedDimensionTypes.Bool]: 'Not contains (case sensitive)',
		[SupportedDimensionTypes.Number]: 'Not contains (case sensitive)',
		[SupportedDimensionTypes.String]: 'Not contains (case sensitive)',
		[SupportedDimensionTypes.Date]: 'Not contains (case sensitive)',
	},
	[SupportedOperators.GreaterThan]: {
		[SupportedDimensionTypes.Bool]: 'Greater than',
		[SupportedDimensionTypes.Number]: 'Greater than',
		[SupportedDimensionTypes.String]: 'Greater than',
		[SupportedDimensionTypes.Date]: 'Greater than',
	},
	[SupportedOperators.GreaterThanOrEqualTo]: {
		[SupportedDimensionTypes.Bool]: 'Greater/Equal to',
		[SupportedDimensionTypes.Number]: 'Greater/Equal to',
		[SupportedDimensionTypes.String]: 'Greater/Equal to',
		[SupportedDimensionTypes.Date]: 'Greater/Equal to',
	},
	[SupportedOperators.LessThan]: {
		[SupportedDimensionTypes.Bool]: 'Less than',
		[SupportedDimensionTypes.Number]: 'Less than',
		[SupportedDimensionTypes.String]: 'Less than',
		[SupportedDimensionTypes.Date]: 'Less than',
	},
	[SupportedOperators.LessThanOrEqualTo]: {
		[SupportedDimensionTypes.Bool]: 'Less/Equal to',
		[SupportedDimensionTypes.Number]: 'Less/Equal to',
		[SupportedDimensionTypes.String]: 'Less/Equal to',
		[SupportedDimensionTypes.Date]: 'Less/Equal to',
	},
	[SupportedOperators.Between]: {
		[SupportedDimensionTypes.Bool]: 'Between',
		[SupportedDimensionTypes.Number]: 'Between',
		[SupportedDimensionTypes.String]: 'Between',
		[SupportedDimensionTypes.Date]: 'Between',
	},
	[SupportedOperators.IsNull]: {
		[SupportedDimensionTypes.Bool]: 'Is null',
		[SupportedDimensionTypes.Number]: 'Is null',
		[SupportedDimensionTypes.String]: 'Is not set',
		[SupportedDimensionTypes.Date]: 'Is null',
	},
	[SupportedOperators.IsNotNull]: {
		[SupportedDimensionTypes.Bool]: 'Is not null',
		[SupportedDimensionTypes.Number]: 'Is not null',
		[SupportedDimensionTypes.String]: 'Is set',
		[SupportedDimensionTypes.Date]: 'Is not null',
	},
	[SupportedOperators.NotBetween]: {
		[SupportedDimensionTypes.Bool]: 'Not between',
		[SupportedDimensionTypes.Number]: 'Not between',
		[SupportedDimensionTypes.String]: 'Not between',
		[SupportedDimensionTypes.Date]: 'Not between',
	},
	[SupportedOperators.NotOneOf]: {
		[SupportedDimensionTypes.Bool]: 'Is not',
		[SupportedDimensionTypes.Number]: 'Not equal',
		[SupportedDimensionTypes.String]: 'Is not',
		[SupportedDimensionTypes.Date]: 'Is not',
	},
	[SupportedOperators.NotOneOfSensitive]: {
		[SupportedDimensionTypes.Bool]: 'Is not (case sensitive)',
		[SupportedDimensionTypes.Number]: 'Is not (case sensitive)',
		[SupportedDimensionTypes.String]: 'Is not (case sensitive)',
		[SupportedDimensionTypes.Date]: 'Is not (case sensitive)',
	},
} as const;
