import Flex from '@components/Flex';
import Typography from '@components/Typography';
import { MouseEvent, useCallback } from 'react';
import { useReportEvent } from '../../../services/analytics';

export type SearchDropdownBottomButtonProps = {
	children: React.ReactNode;
	onClick?: VoidFunction;
	eventName?: string;
	eventMetadata?: object;
	isDisabled?: boolean;
};

export function SearchDropdownBottomButton({
	onClick,
	children,
	eventName,
	eventMetadata,
	isDisabled = false,
}: SearchDropdownBottomButtonProps) {
	const { reportEvent } = useReportEvent();

	const onButtonClick = useCallback(
		(event: MouseEvent) => {
			event.stopPropagation();
			if (isDisabled) return;
			reportEvent({ event: eventName, metaData: eventMetadata });
			onClick?.();
		},
		[isDisabled, eventMetadata, eventName, onClick, reportEvent]
	);

	return (
		<Flex
			height={'44px'}
			justifyContent={'center'}
			alignItems={'center'}
			backgroundColor={'gray.100'}
			cursor={isDisabled ? 'default' : 'pointer'}
			_hover={{ backgroundColor: isDisabled ? 'gray.300' : 'gray.100' }}
			onClick={onButtonClick}
			borderBottomLeftRadius={'7px'}
			borderBottomRightRadius={'7px'}
		>
			<Typography variant={'DesktopH8Medium'} color={isDisabled ? 'gray.300' : 'gray.1000'}>
				{children}
			</Typography>
		</Flex>
	);
}
