import Center from '@components/Center';
import Typography from '@components/Typography';
import Select from 'src/common/components/Select';
import { SupportedDimensionTypes, SupportedOperators } from 'src/generated/graphql';
import { supportedOperatorDisplayNames } from './constants';

export const Operator = ({ operator }: { operator: string }) => {
	return (
		<Center padding={'6px 8px'} cursor={'default'}>
			<Typography variant={'DesktopH10Medium'}>{operator}</Typography>
		</Center>
	);
};

export const OperatorSelect = ({
	operator,
	availableOperators,
	type,
	onSelectOperator,
}: {
	operator: SupportedOperators;
	availableOperators: SupportedOperators[];
	type: SupportedDimensionTypes;
	onSelectOperator: (operator: SupportedOperators) => void;
}) => {
	if (availableOperators.length === 1) return <Operator operator={supportedOperatorDisplayNames[operator][type]} />;
	return (
		<Center padding={'6px 8px'} cursor={'default'}>
			<div data-intercom-target="filter-operator" data-intercom-area={'metric'} data-intercom-type={'main'}>
				<Typography variant={'DesktopH10Medium'}>
					<Select<SupportedOperators>
						options={availableOperators.map((option) => ({
							label: supportedOperatorDisplayNames[option][type],
							value: option,
						}))}
						size={'inline'}
						selectedOption={{
							label: supportedOperatorDisplayNames[operator][type],
							value: operator,
						}}
						onChange={(selectedOption) => onSelectOperator(selectedOption.value)}
					/>
				</Typography>
			</div>
		</Center>
	);
};
