import { Box, ModalHeader } from '@chakra-ui/react';
import Typography from '@components/Typography';
import Button from 'src/common/components/Button';
import Flex from 'src/common/components/Flex';
import { CloseLarge16 } from 'src/common/components/Icons';
import Modal from 'src/common/components/Modal';
import { useReportEvent } from 'src/services/analytics';
import colors from 'src/style/colors';
import Input from '@components/Input';
import { useEffect, useState } from 'react';
import { TextArea } from '@components/Textarea';
import { useInsertCategoryMutation, useEditCategoryMutation } from 'src/generated/graphql';
import { CategoryV2 } from 'src/types/metric';
import useToast from '@hooks/ui/useToast';
import { useInvalidateCache } from '@services/apollo';
import { PermissionsSelector } from '../PermissionsSelector';

type ModalProps = {
	isOpen: boolean;
	onClose: VoidFunction;
	newCategoryName?: string;
	onSubmitCallback?: ({ value, title }: { value: string; title: string }) => void;
	category?: CategoryV2;
};

export const CreateEditCategoryModal = ({
	isOpen,
	onClose,
	newCategoryName = '',
	onSubmitCallback,
	category,
}: ModalProps) => {
	const initialCategoryName = category?.name || newCategoryName;
	const initialCategoryDescription = category?.description || '';
	const isCreateModal = !category;
	const categoryId = category?.id;
	const [categoryName, setCategoryName] = useState('');
	const [categoryDescription, setCategoryDescription] = useState('');
	const [createCategory, { loading: isCreateCategoryLoading }] = useInsertCategoryMutation();
	const [editCategory, { loading: isEditCategoryLoading }] = useEditCategoryMutation();
	const { wrapWithReport } = useReportEvent();
	const toast = useToast();
	const { invalidateCache } = useInvalidateCache();

	useEffect(() => {
		setCategoryName(initialCategoryName);
		setCategoryDescription(initialCategoryDescription);
	}, [initialCategoryName, initialCategoryDescription]);

	const closeModal = () => {
		setCategoryName('');
		setCategoryDescription('');
		onClose();
	};

	const onCreateEditCategoryMutationFail = () => {
		toast({
			variant: 'error',
			message: (
				<>
					<Typography variant="Paragraph14R" color="gray.1000">
						{`Failed to ${isCreateModal ? 'create' : 'edit'} `}
						<Typography variant="Paragraph14SB" color="gray.1000">
							{categoryName}
						</Typography>
						.
					</Typography>
				</>
			),
		});
	};

	const onCreateCategory = () => {
		createCategory({
			variables: {
				category: categoryName,
				description: categoryDescription,
			},
		})
			.then((res) => {
				invalidateCache();
				const value = res.data?.insert_metric_categories_v2?.returning?.[0]?.id;
				const title = res.data?.insert_metric_categories_v2?.returning?.[0]?.category || '';
				onSubmitCallback?.({ value, title });

				toast({
					variant: 'ok',
					message: (
						<>
							<Typography variant="Paragraph14SB" color="gray.1000">
								{categoryName}&nbsp;
								<Typography variant="Paragraph14R" color="gray.1000">
									was successfully created.
								</Typography>
							</Typography>
						</>
					),
				});
			})
			.catch(onCreateEditCategoryMutationFail)
			.finally(() => {
				closeModal();
			});
	};

	const onEditCategory = () => {
		editCategory({
			variables: {
				id: categoryId,
				category: categoryName,
				description: categoryDescription,
			},
		})
			.then(() => {
				invalidateCache();
				toast({
					variant: 'ok',
					message: (
						<>
							<Typography variant="Paragraph14SB" color="gray.1000">
								{categoryName}&nbsp;
								<Typography variant="Paragraph14R" color="gray.1000">
									was successfully edited.
								</Typography>
							</Typography>
						</>
					),
				});
			})
			.catch(onCreateEditCategoryMutationFail)
			.finally(() => {
				closeModal();
			});
	};

	const onSubmit = () => {
		if (isCreateModal) {
			onCreateCategory();
		} else {
			onEditCategory();
		}
	};

	return (
		<Modal
			maxWidth="460px"
			isOpen={isOpen}
			onClose={wrapWithReport(closeModal, 'create-edit-category-modal', { action: 'close' })}
			closeOnOverlayClick={false}
			isCentered
		>
			<div
				data-intercom-area={'catalog'}
				data-intercom-type={'main'}
				data-intercom-target={'create-edit-category-modal'}
			>
				<ModalHeader boxShadow={'borderBottom'} p="16px">
					<Flex textAlign={'center'} justifyContent="space-between" alignItems="center">
						<Button
							isIconOnly
							variant="outline"
							onClick={wrapWithReport(closeModal, 'create-edit-category-modal', { action: 'x' })}
							size="inline"
							colorScheme="black"
						>
							<CloseLarge16 color={colors.gray[900]} />
						</Button>

						<Box margin={'0 auto'}>
							<Typography color={'gray.1100'} marginLeft={'-32px'} variant="DesktopH7Medium">
								{isCreateModal ? 'Create a new category' : 'Edit category'}
							</Typography>
						</Box>
					</Flex>
				</ModalHeader>
				<Flex width={'100%'} gap={'24px'} flexDirection={'column'} boxShadow={'borderBottom'} padding={'24px'}>
					<Input
						size={'sm'}
						label={
							<Typography variant={'DesktopH10Regular'} color={'gray.1000'}>
								{'Category name'}
							</Typography>
						}
						placeholder={'Add name'}
						value={categoryName}
						autoFocus={true}
						onChange={(value) => setCategoryName(value)}
						height={'42px'}
						padding={'11px 16px 11px 12px'}
						borderRadius={'8px'}
						borderColor={'gray.300'}
						color={'gray.1000'}
					/>
					<TextArea
						label={
							<Typography variant={'DesktopH10Regular'} color={'gray.1000'}>
								{'Description'}
							</Typography>
						}
						placeholder={'Detailed about this category'}
						value={categoryDescription}
						onChange={(value) => setCategoryDescription(value)}
						height={'120px'}
						padding={'12px'}
						borderRadius={'8px'}
						borderColor={'gray.300'}
						color={'gray.1000'}
						fontWeight={'400'}
						fontSize={'14px'}
						lineHeight={'20px'}
					/>
					<Flex flexDirection={'column'} gap={'8px'}>
						<Typography variant={'DesktopH10Regular'} color={'gray.1000'}>
							{'Permissions'}
						</Typography>
						{/*TODO: update with component when its functionality will be implemented*/}
						<PermissionsSelector />
					</Flex>
				</Flex>
				<Flex py="16px" px="24px" justifyContent={'space-between'}>
					<Button
						color={'gray.1000'}
						size={'medium'}
						colorScheme="gray"
						variant="outline"
						isDisabled={isCreateCategoryLoading || isEditCategoryLoading}
						onClick={wrapWithReport(closeModal, 'create-edit-category-modal', { action: 'cancel' })}
					>
						Cancel
					</Button>
					<Button
						data-intercom-area={'catalog'}
						data-intercom-type={'button'}
						data-intercom-target={isCreateModal ? 'create-new-category-button' : 'edit-category-button'}
						isDisabled={!categoryName || isCreateCategoryLoading || isEditCategoryLoading}
						size={'medium'}
						variant="solid"
						colorScheme={'blue'}
						onClick={wrapWithReport(onSubmit, 'create-edit-category-modal', {
							action: isCreateModal ? 'create' : 'edit',
						})}
					>
						{isCreateModal ? 'Create category' : 'Save'}
					</Button>
				</Flex>
			</div>
		</Modal>
	);
};
