import {
	formatDimensionLabel,
	formatMetricFilterLabel,
	replaceLastCommaWithOr,
} from '@components/LeftExpandCollapsePanel/Drilldown/FilterDrilldownCard/utils';
import { Abc16, Boolean16, HashMark16 } from '@icons/index';
import { FilterV2, FilterV2Value } from '@pages/MetricPage/utils/state.types';
import { useCallback, useMemo } from 'react';
import { useSemanticDefinitions } from 'src/common/hooks/stores/useSemanticDefinitions';
import { useModal } from 'src/common/hooks/ui/useModal';
import { SupportedDimensionTypes } from 'src/generated/graphql';
import { MonacoTooltipComponent } from 'src/lib/completions/widgetBuilder/MonacoTooltipComponent';
import { getContextCompletionTooltip } from 'src/lib/completions/widgetBuilder/contextTooltip';
import { resolveContextFromSemanticsAndName } from 'src/pages/OntologyPage/components/JoinKeys/utils';
import { DrilldownCard } from '../DrillDownCard';
import { ValueEditorByFilter } from './Editors/ValueEditorByFilter';
import { OperatorSelect } from './Operator';
import { supportedOperatorsByFilterType } from './constants';
import { useMetricFilter2 } from './useMetricFilter2';

export const FilterDrilldownCard2 = ({
	filter,
	onRemove,
	isEditorAutoOpened,
	eventMetadata,
}: {
	filter: FilterV2;
	onRemove?: VoidFunction;
	isEditorAutoOpened?: boolean;
	eventMetadata: object;
}) => {
	const { label, values } = filter;
	const formattedLabel = formatDimensionLabel(label);
	const {
		isOpen: isEditing,
		onClose: onCloseEditor,
		onOpen: onOpenEditor,
	} = useModal({ defaultIsOpen: isEditorAutoOpened });

	const { isLoadingDimensionValues, options, updateFilter, dimensionsEntity, dimensionName } = useMetricFilter2({
		filter,
	});

	const { semanticDefinitions } = useSemanticDefinitions();

	const extendedEventMetadata = {
		...eventMetadata,
		dimension: filter?.key,
		dimensionType: filter?.type,
	};

	const texts = useMemo(() => {
		const firstCommaSeparatedValuesLabels = values
			?.map((v) => formatMetricFilterLabel(v?.toString() ?? null))
			?.filter((v) => v !== '')
			?.map(formatMetricFilterLabel)
			.join(', ');
		const displayValue = values && replaceLastCommaWithOr(firstCommaSeparatedValuesLabels);

		return {
			title: formattedLabel,
			displayValue,
		};
	}, [values, formattedLabel]);

	const { title, displayValue } = texts;

	const icon = useMemo(() => {
		switch (filter.type) {
			case SupportedDimensionTypes.String:
				return <Abc16 />;
			case SupportedDimensionTypes.Number:
				return <HashMark16 />;
			case SupportedDimensionTypes.Bool:
				return <Boolean16 />;
		}
	}, [filter.type]);

	const onApply = useCallback(
		(values: FilterV2Value[]) => {
			updateFilter(values, filter.operator);
		},
		[filter.operator, updateFilter]
	);

	return (
		<DrilldownCard
			icon={icon}
			onRemove={onRemove}
			removeTooltip={'Remove filter'}
			title={title}
			eventMetadata={extendedEventMetadata}
			valueEditor={
				<ValueEditorByFilter
					filter={filter}
					options={options}
					displayValue={displayValue}
					values={values}
					onApply={onApply}
					prefixComponent={
						<OperatorSelect
							operator={filter.operator}
							availableOperators={supportedOperatorsByFilterType[filter.type]}
							type={filter.type}
							onSelectOperator={(operator) => {
								updateFilter(values, operator);
							}}
						/>
					}
					isLoadingDimensionValues={isLoadingDimensionValues}
					eventMetadata={extendedEventMetadata}
					isEditing={isEditing}
					onOpenEditor={onOpenEditor}
					onCloseEditor={onCloseEditor}
				/>
			}
			isEditing={isEditing}
			titleHoverTooltipBuilder={() => {
				if (!dimensionName || !semanticDefinitions || !dimensionsEntity) return;
				const context = resolveContextFromSemanticsAndName(semanticDefinitions, dimensionsEntity, dimensionName);
				if (!context) return;
				return <MonacoTooltipComponent tooltipMarkdownString={getContextCompletionTooltip(context, dimensionName)} />;
			}}
		/>
	);
};
