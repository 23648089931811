import { Box, Flex } from '@chakra-ui/react';
import Typography from 'src/common/components/Typography';
import colors from 'src/style/colors';
import { EditableValueContainer } from '../../Editors/EditableValueContainer';
import { InputStringEditor } from '../../Editors/InputStringEditor';
import { ValueEditorProps } from './ValueEditorByFilter';

export function FilterTwoNumbersEditor({ filter, onApply, prefixComponent }: ValueEditorProps) {
	return (
		<EditableValueContainer prefixComponent={prefixComponent}>
			<Flex flexDir={'row'} alignItems={'center'}>
				<Box flex={1}>
					<InputStringEditor
						value={filter.values[0]?.toString() || ''}
						onApply={(value: string) => {
							onApply([value, filter.values[1]?.toString() ?? '']);
						}}
						type="number"
					/>
				</Box>
				<Typography variant={'DesktopH10Regular'} color={colors.gray[600]} pl={'8px'}>
					and
				</Typography>

				<Box flex={1}>
					<InputStringEditor
						value={filter.values[1]?.toString() || ''}
						onApply={(value: string) => {
							onApply([filter.values[0]?.toString() ?? '', value]);
						}}
						type="number"
					/>
				</Box>
			</Flex>
		</EditableValueContainer>
	);
}
