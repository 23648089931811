import Box from '@components/Box';
import { AskAIInput } from '../AskAIInput';
import { AskAIModalItem } from '../AskAIModalItem';
import { AskAIChatHeader } from './AskAIChatHeader';
import { AskAIChatCardsList } from './AskAIChatCardList';
import { useCallback } from 'react';
import { useAskAIChatMessages } from '../hooks/useAskAIChatMessages';
import { DrawerFooter, DrawerHeader } from '../../Drawer';

export type AskAIChatPageProps = {
	onBack: () => void;
	onClose: () => void;
};

export function AskAIChatPage({ onBack, onClose }: AskAIChatPageProps) {
	const { addNewUserPrompt } = useAskAIChatMessages();

	const onSubmit = useCallback(
		(userPrompt: string) => {
			addNewUserPrompt(userPrompt);
		},
		[addNewUserPrompt]
	);

	return (
		<>
			<DrawerHeader padding={0}>
				<AskAIModalItem borderBottom={true}>
					<AskAIChatHeader onBack={onBack} onClose={onClose} />
				</AskAIModalItem>
			</DrawerHeader>

			<AskAIChatCardsList />

			<DrawerFooter padding={0}>
				<AskAIModalItem backgroundColor={'gray.100'} borderTop={true}>
					<Box padding={'8px'} flex={1}>
						<AskAIInput
							placeholder={'Ask any business question'}
							hasBorder={true}
							onSubmit={onSubmit}
							reportEvent={'ask-ai-chat-query'}
							reportFeature={'Ask AI'}
						/>
					</Box>
				</AskAIModalItem>
			</DrawerFooter>
		</>
	);
}
